var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    { tag: "component" },
    [
      _vm.state === "loading" ? _vm._t("loading") : _vm._e(),
      _vm._v(" "),
      _vm.state === "loaded" ? _vm._t("loaded") : _vm._e(),
      _vm._v(" "),
      _vm.state === "error" ? _vm._t("error") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }