import Vue from 'vue';

export default function() {
  Vue.component('bridge-map', {
    data: function() {
      return {
        locations: [],
        
        coordinates: [
          {
            top: '86%',
            left: '30%',
            country: 'UY'
          },
          {
            top: '88%',
            left: '28%',
            country: 'AG'
          },
          {
            top: '84%',
            left: '26.5%',
            country: 'CL'
          },
          {
            top: '34%',
            left: '46%',
            country: 'GB'
          },
          {
            top: '35.5%',
            left: '48%',
            country: 'BE'
          },
          {
            top: '33.5%',
            left: '49.5%',
            country: 'DE'
          },
          {
            top: '36.5%',
            left: '50.5%',
            country: 'AT'
          },
          {
            top: '41.5%',
            left: '50%',
            country: 'IT'
          },
          {
            top: '42%',
            left: '58.5%',
            country: 'IL-1'
          },
          { 
            top: '44.5%',
            left: '23.5%',
            country: 'US'
          },
          { 
            top: '36.5%',
            left: '24%',
            country: 'CA'
          },
          { 
            top: '63.5%',
            left: '26%',
            country: 'CO'
          },
          { 
            top: '80.5%',
            left: '53%',
            country: 'SA'
          },
          { 
            top: '84.5%',
            left: '89%',
            country: 'AU'
          },
          { 
            top: '38.5%',
            left: '47%',
            country: 'FR'
          },
          { 
            top: '36%',
            left: '53%',
            country: 'HU'
          },
          { 
            top: '28%',
            left: '50%',
            country: 'SE'
          }
        ],
        current: false
      };
    }
  });
}
