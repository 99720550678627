var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "reveal-wrapper",
        style: { "max-height": _vm.isActive ? "10000px" : _vm.maxHeight },
      },
      [
        _c("div", { staticClass: "reveal-inner" }, [_vm._t("default")], 2),
        _vm._v(" "),
        !_vm.isActive
          ? _c("div", {
              staticClass: "reveal-gradient",
              attrs: { role: "presentation" },
            })
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "reveal-button mt-4",
        attrs: { type: "button" },
        on: {
          click: function ($event) {
            _vm.isActive = !_vm.isActive
          },
        },
      },
      [_vm._v("\n    Read " + _vm._s(_vm.isActive ? "Less" : "More") + "\n  ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }