<template>
  <button
    class="elevate-representative-filter-selector"
    @click.prevent="showDropDown = !showDropDown"
    v-click-outside="onClickOutside"
  >
    <span>{{ stateValue }}</span>
    <svg class="ml-auto" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 9L12 15L18 9" stroke="#101828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>


    <ul v-if="showDropDown">
      <li v-for="(option, i) in allOptions" :key="i">
        <button @click.prevent="select(option)">
          {{ option }}
        </button>
      </li>
    </ul>
  </button>
</template>

<script>
import vClickOutside from 'v-click-outside';

export default {
  data() {
    return {
      showDropDown: false,
      stateValue: this.placeholder
    };
  },

  props: {
    options: { required: true, type: Array },
    value: { required: true, type: String },
    initialValue: { required: true, type: String },
    placeholder: { required: true, type: String }
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  watch: {
    options() {
      if (!this.options.includes(this.value)) {
        // The new set of languages does not contain the selected language
        // Reset the select to "All Languages"
        this.add(this.initialValue);
      }
    }
  },

  computed: {
    allOptions() {
      return [this.initialValue, ...this.options];
    }
  },

  methods: {
    select(option) {
      this.$emit('input', option);
      this.stateValue = option;
    },
    add(option) {
      this.$emit('input', option);
    },
    onClickOutside() {
      this.showDropDown = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.elevate-representative-filter-selector {
  @apply flex items-center;
  @apply relative;
  @apply rounded-sm;
  @apply pl-4 py-3;
  @apply text-lg font-medium text-blue-0C2B46;
  background: white;
  @apply font-suisse-intl;

  height: 3.375rem;
  min-width: 16.5rem;

  &:focus,
  &:active {
    outline: none;
  }

  img {
    @apply px-4;
    @apply ml-auto;
  }

  ul {
    @apply absolute;
    @apply w-full text-blue-0C2B46 bg-light;

    top: 3.375rem;
    left: 0;

    button {
      @apply w-full;
      @apply text-lg text-left capitalize;
      @apply px-6 py-3;
      @apply font-suisse-intl text-blue-0C2B46;

      &:hover {
       @apply bg-gray-light;
      }
    }
  }

  .image-rotate {
    @apply transform rotate-180;
  }
}
</style>
