import Vue from 'vue';
import debounce from 'lodash/debounce';

export default function() {
  Vue.component('hero-image', {
    data: function() {
      return {
        height: '700px',
        timeout: null
      };
    },

    mounted() {
      // Refresh either on resize or on a incrementing interval
      window.addEventListener('resize', this.onResize);
      this.maintain();
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
      clearTimeout(this.timeout);
    },

    methods: {
      onResize: debounce(function() {
        const el = document.getElementById('inner-hero-container');
        const targetHeight = `${el.offsetHeight}px`;

        if (el && el.height !== targetHeight) {
          el.height = targetHeight;
        }
      }, 200),

      maintain(duration = 200) {
        if (this.timeout) clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
          this.onResize();
          this.maintain(duration * 2);
        }, duration);
      }
    }
  });
}
