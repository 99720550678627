/* global $cookies */
export default {
  data() {
    return {
      visible: false,
      isSettingsVisible: false
    };
  },
  mounted() {
    const settings = $cookies.get('cookie-settings') || false;

    if (!settings) {
      this.visible = true;
    }
  },
  methods: {
    accept() {
      let statistics = true;
      let marketing = true;

      if (this.isSettingsVisible) {
        statistics = document.getElementById('statistics-cookies').checked;
        marketing = document.getElementById('marketing-cookies').checked;
      }

      let settings = JSON.stringify({ statistics: statistics, marketing: marketing });
      $cookies.set('cookie-settings', settings, new Date('01 Jan 2037 00:00:00 UTC'));
      this.visible = false;
    }
  }
};
