import vue from './vue';
import setupDisabledButton from './disabled-button';
import closeFact from './close-fact';
import unhrc from './unhrc-fact';
import {
  parseVideos as parseFacebookVideos,
  insertSDK as insertFacebookSDK
} from '../facebook-video/index';
import copyClipboard from './copy_clipboard';

export default function() {
  vue();
  setupDisabledButton();
  closeFact();
  unhrc();
  copyClipboard();

  insertFacebookSDK();
  window._parseFacebookVideos = parseFacebookVideos;
}
