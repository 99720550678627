import { render, staticRenderFns } from "./trinity-audio.vue?vue&type=template&id=225d549a&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/build_d5d95d2b/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('225d549a')) {
      api.createRecord('225d549a', component.options)
    } else {
      api.reload('225d549a', component.options)
    }
    module.hot.accept("./trinity-audio.vue?vue&type=template&id=225d549a&", function () {
      api.rerender('225d549a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/javascripts/components/trinity-audio.vue"
export default component.exports