<template>
  <div id="date-picker" v-cloak="v - cloak">
    <div class="grid grid-cols-3 bg-light px-4 py-2 input-container h-full">
      <div>
        <input
          class="bg-light text-primary flex-1 date-input w-full text-sm"
          placeholder="Start date"
          type="text"
          v-model="showDate"
          @input="updatePickedDate($event.target.value)"
          @focus="onFocus()"
          @blur.prevent="onBlur()"
          name="from"
        />
      </div>
      <span
        class="text-primary text-lg font-medium font-avenir text-center mt-1"
        style="min-width: 50px;"
      >
        - to -
      </span>
      <div>
        <input
          class="bg-light text-primary  flex-1 date-input w-full text-sm"
          placeholder="End date"
          type="text"
          v-model="showEndDate"
          @input="updateLastDate($event.target.value)"
          @focus="onFocus()"
          @blur.prevent="onBlur()"
          name="to"
        />
      </div>
    </div>

    <!-- use the two events preventers in the div below this comment if any inconsistent user behaviour occurs
      @mousedown.prevent.stop="onMouseDown()"
      @mouseup.prevent.stop="onMouseUp()"
    -->
    <div
      class="bg-white rounded pb-4 main-container"
      style="box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.15);"
      :class="{ flex: open }"
      v-show="open"
      @mousedown.prevent.stop="onMouseDown()"
      @mouseup.prevent.stop="onMouseUp()"
    >
      <slot></slot>
      <div class="calendar-container flex flex-col px-4">
        <div class="calendar font-primary ">
          <div class="calendar-header">
            <div class="center flex">
              <div class="month-selector">
                <just-a-dropdown
                  :title="title"
                  :options="monthsOptions"
                  @toggle-choice="selectMonth"
                >
                </just-a-dropdown>
              </div>
              <div class="year-selector">
                <div class="left arrow" @click="changeCurMonth(-12)">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.414 12L13.889 14.475L13.182 15.182L10 12L13.182 8.81799L13.889 9.52499L11.414 12Z"
                      fill="#2D3748"
                    />
                  </svg>
                </div>
                <div class="right arrow" @click="changeCurMonth(12)">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.5863 12L10.1113 9.52499L10.8183 8.81799L14.0003 12L10.8183 15.182L10.1113 14.475L12.5863 12Z"
                      fill="#2D3748"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <table>
            <thead>
              <tr class="week font-primary text-xs font-medium">
                <td>Sun</td>
                <td>Mon</td>
                <td>Tue</td>
                <td>Wen</td>
                <td>Thu</td>
                <td>Fri</td>
                <td>Sat</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in rows">
                <td
                  class="day "
                  v-for="date in row"
                  :class="{
                    'other-month': date.getMonth() !== curMonth,
                    selected: date >= pickedDate && date <= endDate,
                    hover:
                      pickedDate != 0 &&
                      date >= pickedDate &&
                      date <= hoverEndDate
                  }"
                  @click="pickDate(date)"
                  @mouseenter="hover(date)"
                  @mouseleave="out()"
                >
                  {{ date.getDate() }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="flex">
          <div
            class="btn mt-4 mb-4 ml-4 mr-3"
            style="background-color: #f7f7f7;"
            @click="clearDates()"
          >
            Clear
          </div>
          <button type="submit" class="btn btn-tertiary mt-4 mb-4 mr-4 ml-3">
            Done
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export default {
  props: {
    from: { type: String, required: true },
    to: { type: String, required: true }
  },

  data() {
    const d = new Date();
    return {
      // initial value must always be false!
      open: false,
      // initial value must always be false!
      forceOpen: false,
      pickedDate: this.from,
      selectEndDate: false,
      endDate: this.to,
      hoverEndDate: d,
      curYear: d.getFullYear(),
      curMonth: d.getMonth(),
      curDay: d.getDate(),
      dateInitialised: false,
      monthsOptions: [
        { text: 'Jan', value: 0 },
        { text: 'Feb', value: 1 },
        { text: 'Mar', value: 2 },
        { text: 'Apr', value: 3 },
        { text: 'May', value: 4 },
        { text: 'Jun', value: 5 },
        { text: 'Jul', value: 6 },
        { text: 'Aug', value: 7 },
        { text: 'Sep', value: 8 },
        { text: 'Oct', value: 9 },
        { text: 'Nov', value: 10 },
        { text: 'Dec', value: 11 }
      ]
    };
  },
  computed: {
    title: {
      get() {
        return `${this.monthsOptions[this.curMonth].text} ${this.curYear}`;
      }
    },

    showDate: {
      get() {
        let d = new Date(this.pickedDate);

        if (!this.endDate) {
          this.selectEndDate = true;
        }
        if (d != null && d != 0 && d != 'Invalid Date') {
          return `${d.getFullYear()}-${(d.getMonth() < 9 ? '0' : '') +
            (d.getMonth() + 1)}-${(d.getDate() < 10 ? '0' : '') + d.getDate()}`;
        } else {
          return;
        }
      }
    },

    showEndDate: {
      get() {
        let e = new Date(this.endDate);

        if (!this.endDate) {
          this.selectEndDate = true;
        }
        if (e != null && e.length != 0 && e != 'Invalid Date') {
          return `${e.getFullYear()}-${(e.getMonth() < 9 ? '0' : '') +
            (e.getMonth() + 1)}-${(e.getDate() < 10 ? '0' : '') + e.getDate()}`;
        } else {
          return;
        }
      }
    },

    leapYear() {
      return this.curYear % 100 === 0
        ? this.curYear % 400 === 0
        : this.curYear % 4 === 0;
    },

    dates() {
      const ret = [];
      const firstDayOfMonth = new Date(this.curYear, this.curMonth, 1);
      const lastDayOfMonth = new Date(
        this.curYear,
        this.curMonth,
        this.getLastDayOfMonth(this.curMonth)
      );
      ret.unshift(firstDayOfMonth);
      for (
        let d = this.prevDate(firstDayOfMonth);
        d.getDay() !== 6;
        d = this.prevDate(d)
      ) {
        ret.unshift(d);
      }
      for (
        let d = this.nextDate(firstDayOfMonth);
        d <= lastDayOfMonth;
        d = this.nextDate(d)
      ) {
        ret.push(d);
      }
      for (
        let d = this.nextDate(lastDayOfMonth);
        d.getDay() !== 0;
        d = this.nextDate(d)
      ) {
        ret.push(d);
      }
      return ret;
    },

    rows() {
      return this.dates.reduce(function(p, c, i) {
        if (i % 7 === 0) {
          p[p.length] = [];
        }
        p[p.length - 1].push(c);
        return p;
      }, []);
    }
  },

  methods: {
    selectMonth(n) {
      this.curMonth = n;
    },

    changeCurMonth(n) {
      const temp = this.curMonth + n;
      if (temp < 0) {
        this.curYear -= 1;
      } else if (temp >= 12) {
        this.curYear += 1;
      }
      this.curMonth = (temp + 12) % 12;
    },

    nextDate(d) {
      const ret = new Date(d.valueOf());
      ret.setDate(d.getDate() + 1);
      return ret;
    },

    prevDate(d) {
      const ret = new Date(d.valueOf());
      ret.setDate(d.getDate() - 1);
      return ret;
    },

    getLastDayOfMonth(m) {
      const ret = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (this.leapYear) {
        ret[1] = 29;
      }
      return ret[m];
    },

    pickDate(d) {
      if (!this.dateInitialised) {
        this.setPickedDate(d);
        this.dateInitialised = true;
        return;
      }

      if (!this.selectEndDate || d.valueOf() < this.pickedDate.valueOf()) {
        this.setPickedDate(d);
      } else if (
        this.selectEndDate &&
        d.valueOf() >= this.pickedDate.valueOf()
      ) {
        this.endDate = d;
        this.curMonth = d.getMonth();
        this.curYear = d.getFullYear();
        this.selectEndDate = false;
      }
    },

    setPickedDate(date) {
      this.endDate = date;
      this.pickedDate = date;
      this.curMonth = date.getMonth();
      this.curYear = date.getFullYear();
      this.selectEndDate = true;
      this.hoverEndDate = date;
    },

    hover(d) {
      if (this.selectEndDate && d.valueOf() >= this.pickedDate.valueOf()) {
        this.hoverEndDate = d;
      }
    },

    out() {
      this.hoverEndDate = this.pickedDate;
    },

    onFocus() {
      this.open = true;
      this.forceOpen = false;
    },

    onBlur() {
      this.open = this.forceOpen;
    },

    onMouseDown() {
      this.forceOpen = true;
    },

    onMouseUp() {
      this.forceOpen = false;
    },

    updatePickedDate(v) {
      const start = updateGenericDate(v, false);
      this.pickedDate = start;
    },

    updateLastDate(v) {
      const end = updateGenericDate(v, true);
      this.endDate = end;
    },

    updateGenericDate(v, isLastDate) {
      const reg = /^(?<startYear>\d{4})-(?<startMonth>\d{2})-(?<startDay>\d{2}) ~ (?<endYear>\d{4})-(?<endMonth>\d{2})-(?<endDay>\d{2})$/;
      const m = reg.exec(v);

      if (m) {
        const startYear = parseInt(m.groups.startYear);
        const startMonth = parseInt(m.groups.startMonth);
        const startDay = parseInt(m.groups.startDay);
        const endYear = parseInt(m.groups.endYear);
        const endMonth = parseInt(m.groups.endMonth);
        const endDay = parseInt(m.groups.endDay);

        if (
          (startMonth > 12 || startMonth < 1) &&
          (endMonth > 12 || endMonth < 1)
        ) {
          return;
        }
        if (startDay < 1 || startDay > this.getLastDayOfMonth(startMonth)) {
          return;
        }
        if (endDay < 1 || endDay > this.getLastDayOfMonth(endMonth)) {
          return;
        }

        const start = new Date(startYear, startMonth - 1, startDay);
        const end = new Date(endYear, endMonth - 1, endDay);

        if (start.valueOf() > end.valueOf()) {
          return;
        }

        this.curYear = endYear;
        this.curMonth = endMonth - 1;

        if (isLastDate) {
          return end;
        } else {
          return start;
        }
      }
    },

    clearDates() {
      this.pickedDate = 0;
      this.endDate = null;
      this.dateInitialised = false;
    }
  },

  filters: {
    formatMonth(m) {
      return months[m];
    }
  }
};
</script>

<style lang="scss" scoped>
$calendarBackground: lighten(white, 40%);
$selectedColor: #c5dfff;
$hoverColor: lighten(steelblue, 40%);
$arrowHoverColor: steelblue;
$weekColor: #b8b9ba;
$dateBorderRadius: 0;

.other-month {
  color: gray;
  opacity: 0;
}

.arrow {
  cursor: pointer;
  width: 1.5em;
}

.arrow:hover {
  color: $arrowHoverColor;
  font-weight: 400;
}

.year-selector {
  @apply flex ml-auto;

  width: fit-content;
}

.year,
.month {
  height: 1.2em;
}

.left {
  float: left;
}

.right {
  float: right;
}

.center {
  text-align: center;
  clear: both;
}

table {
  clear: both;
}

table .week td {
  @apply font-primary;
  color: $weekColor;
}

table td {
  width: 32px;
  height: 32px;
  vertical-align: middle;
  text-align: center;
}

tbody td {
  cursor: pointer;
  border-radius: $dateBorderRadius;
}

tbody td.selected {
  @apply rounded-full;
  background: $selectedColor !important;
}

tbody td.selected:hover {
  @apply rounded-full;
  background: $selectedColor;
}

tbody td.hover {
  @apply rounded-full;
  background: lighten($selectedColor, 10%);
}

tbody td:hover {
  @apply rounded-full;

  background-color: $hoverColor;
}

.calendar {
  @apply pt-4;

  z-index: 1;
  background-color: $calendarBackground;
  border-radius: 5px;
  padding: 0.5em 0.2em;
  position: relative;
}

.calendar:before {
  content: '';
  width: 0;
  height: 0;
  border: solid transparent 8px;
  border-bottom: solid $calendarBackground 8px;
}

[v-cloak] {
  display: none;
}

.month-select-element {
  position: relative;
  z-index: 1000000;

  &:hover {
    cursor: pointer;
  }
}

.input-container {
  max-width: 270px;

  @screen lg {
    max-width: 290px;
  }
}

.main-container {
  @apply absolute;
}

.dropdown-option {
  &:hover {
    @apply underline;
    cursor: pointer;
  }
}
</style>
