<script>
export default {
  props: {
    state: { required: true, type: Object }
  },

  render() {
    return this.$scopedSlots.default({
      state: this.stateData,
      setState: this.set
    });
  },

  data() {
    return {
      stateData: this.state
    };
  },

  mounted() {
    window.addEventListener('scroll', this.selectSection);
  },

  methods: {
    set(property, value) {
      this.$set(this.stateData, property, value);
    },

    // Active scroll - add active class to table of content items when user scrolls down or up
    selectSection() {
      const scrollPos =
        document.documentElement.scrollTop + 300 ||
        document.body.scrollTop + 300;

      const sections = document.querySelectorAll('.community-section');

      for (let s in sections) {
        if (sections.hasOwnProperty(s) && sections[s].offsetTop <= scrollPos) {
          const id = sections[s].id;
          this.stateData.category = id;
        }
      }
    }
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.selectSection);
  }
};
</script>
