import Vue from 'vue';

export default function() {
  Vue.component('igrc-map', {
    props: ['info', 'hezbollahInfo'],

    data: function() {
      return {
        locations: [],
        hezbollahCoordinates: [
          {
            top: '27%',
            left: '55%'
          },
          {
            top: '34%',
            left: '57%'
          },
          {
            top: '40%',
            left: '60%'
          },
          {
            top: '87%',
            left: '35%'
          }
        ],
        
        coordinates: [
          {
            top: '35%',
            left: '66%'
          },
          {
            top: '93%',
            left: '31%'
          },
          {
            top: '25%',
            left: '53%'
          },
          {
            top: '30%',
            left: '61%'
          },
          {
            top: '40%',
            left: '62%'
          },
          {
            top: '23%',
            left: '51%'
          },
          {
            top: '36%',
            left: '63.5%'
          },
          {
            top: '37%',
            left: '58%'
          },
          {
            top: '36%',
            left: '58.5%'
          },
          {
            top: '42%',
            left: '24%'
          },
          {
            top: '42%',
            left: '60.5%'
          },
          {
            top: '35%',
            left: '59%'
          },
          {
            top: '31%',
            left: '57%'
          },
          {
            top: '31%',
            left: '29%'
          },
          {
            top: '40%',
            left: '60.5%'
          }
        ],
        current: false
      };
    },

    mounted() {
      if(this.info !== undefined) {
        for(let i=0; i < this.info.length; i ++) {
          if(this.coordinates[i] != undefined) {
            let anchorLinkData = [];
  
            anchorLinkData.push(JSON.parse(this.info[i][0]));
            anchorLinkData.push(this.coordinates[i]);
            this.locations.push(anchorLinkData);
          }
        }
      }
      else {
        for(let i=0; i < this.hezbollahInfo.length; i ++) {
          if(this.hezbollahCoordinates[i] != undefined) {
            let anchorLinkData = [];
  
            anchorLinkData.push(JSON.parse(this.hezbollahInfo[i][0]));
            anchorLinkData.push(this.hezbollahCoordinates[i]);
            this.locations.push(anchorLinkData);
          }
        }
      }

      this.current = this.locations[0];
    }
  });
}
