export function initDeepLinks() {
  const editIconUrl = document.querySelector('meta[name="deep-link-icon-url"]')
    .content;

  window.deepLinkHighlightEnabled = false;
  window.convertDeepLinks = () => {
    document
      .querySelectorAll('.deep-link:not(.deep-link-initialized)')
      .forEach((el) => {
        const template = `
        <a
          href="${el.dataset.link}"
          class="deep-link-edit-btn"
          @click.prevent.stop="openLink"
          title="Edit ${el.dataset.link}"
        >
          <img src="${editIconUrl}" />
        </a>
        <div class="deep-link-overlay">
          <div class="label">${el.dataset.label}</div>
        </div>`;

        el.insertAdjacentHTML('beforeend', template);
        el.classList.add('deep-link-initialized');
      });

    if (window.deepLinkHighlightEnabled) {
      highlightDeepLinks();
    }
  };

  window.highlightDeepLinks = () => {
    window.deepLinkHighlightEnabled = true;
    document.querySelectorAll('.deep-link').forEach((el) => {
      el.classList.add('highlight');
    });
  };

  window.stopHighlightingDeepLinks = () => {
    window.deepLinkHighlightEnabled = false;
    document.querySelectorAll('.deep-link').forEach((el) => {
      el.classList.remove('highlight');
    });
  };

  setInterval(() => {
    convertDeepLinks();
  }, 1000);
}
