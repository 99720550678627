import './avenir/Avenir-Book.eot';
import './avenir/Avenir-Book.woff2';
import './avenir/Avenir-Book.woff';
import './avenir/Avenir-Book.ttf';
import './avenir/Avenir-Book.svg';

import './avenir/Avenir-Medium.eot';
import './avenir/Avenir-Medium.woff2';
import './avenir/Avenir-Medium.woff';
import './avenir/Avenir-Medium.ttf';
import './avenir/Avenir-Medium.svg';

import './avenir/Avenir-Heavy.eot';
import './avenir/Avenir-Heavy.woff2';
import './avenir/Avenir-Heavy.woff';
import './avenir/Avenir-Heavy.ttf';
import './avenir/Avenir-Heavy.svg';

import './atlasPro/AtlasProAAA-Regular.eot';
import './atlasPro/AtlasProAAA-Regular.woff2';
import './atlasPro/AtlasProAAA-Regular.woff';
import './atlasPro/AtlasProAAA-Regular.ttf';
import './atlasPro/AtlasProAAA-Regular.svg';

import './suisse-works/SuisseWorks-Bold.otf';
import './suisse-works/SuisseWorks-Bold.ttf';
import './suisse-works/SuisseWorks-BoldItalic.otf';
import './suisse-works/SuisseWorks-BoldItalic.ttf';
import './suisse-works/SuisseWorks-Book.otf';
import './suisse-works/SuisseWorks-Book.ttf';
import './suisse-works/SuisseWorks-BookItalic.otf';
import './suisse-works/SuisseWorks-BookItalic.ttf';
import './suisse-works/SuisseWorks-Medium.otf';
import './suisse-works/SuisseWorks-Medium.ttf';
import './suisse-works/SuisseWorks-MediumItalic.otf';
import './suisse-works/SuisseWorks-MediumItalic.ttf';
import './suisse-works/SuisseWorks-Regular.otf';
import './suisse-works/SuisseWorks-Regular.ttf';
import './suisse-works/SuisseWorks-RegularItalic.otf';
import './suisse-works/SuisseWorks-RegularItalic.ttf';

import './suisse-intl/SuisseIntl-Black-WebM.woff2';
import './suisse-intl/SuisseIntl-Black-WebS.woff2';
import './suisse-intl/SuisseIntl-Black-WebXL.woff2';
import './suisse-intl/SuisseIntl-BlackItalic-WebM.woff2';
import './suisse-intl/SuisseIntl-BlackItalic-WebS.woff2';
import './suisse-intl/SuisseIntl-BlackItalic-WebXL.woff2';
import './suisse-intl/SuisseIntl-Bold-WebS.woff2';
import './suisse-intl/SuisseIntl-Bold-WebM.woff2';
import './suisse-intl/SuisseIntl-Bold-WebXL.woff2';
import './suisse-intl/SuisseIntl-BoldItalic-WebXL.woff2';
import './suisse-intl/SuisseIntl-BoldItalic-WebS.woff2';
import './suisse-intl/SuisseIntl-BoldItalic-WebM.woff2';
import './suisse-intl/SuisseIntl-Book-WebXL.woff2';
import './suisse-intl/SuisseIntl-Book-WebS.woff2';
import './suisse-intl/SuisseIntl-Book-WebM.woff2';
import './suisse-intl/SuisseIntl-BookItalic-WebXL.woff2';
import './suisse-intl/SuisseIntl-BookItalic-WebS.woff2';
import './suisse-intl/SuisseIntl-BookItalic-WebM.woff2';
import './suisse-intl/SuisseIntl-Light-WebXL.woff2';
import './suisse-intl/SuisseIntl-Light-WebS.woff2';
import './suisse-intl/SuisseIntl-Light-WebM.woff2';
import './suisse-intl/SuisseIntl-LightItalic-WebXL.woff2';
import './suisse-intl/SuisseIntl-LightItalic-WebM.woff2';
import './suisse-intl/SuisseIntl-LightItalic-WebS.woff2';
import './suisse-intl/SuisseIntl-MediumItalic-WebXL.woff2';
import './suisse-intl/SuisseIntl-MediumItalic-WebS.woff2';
import './suisse-intl/SuisseIntl-MediumItalic-WebM.woff2';
import './suisse-intl/SuisseIntl-Medium-WebS.woff2';
import './suisse-intl/SuisseIntl-Medium-WebM.woff2';
import './suisse-intl/SuisseIntl-Medium-WebXL.woff2';
import './suisse-intl/SuisseIntl-Regular-WebS.woff2';
import './suisse-intl/SuisseIntl-Regular-WebM.woff2';
import './suisse-intl/SuisseIntl-Regular-WebXL.woff2';
import './suisse-intl/SuisseIntl-RegularItalic-WebS.woff2';
import './suisse-intl/SuisseIntl-RegularItalic-WebM.woff2';
import './suisse-intl/SuisseIntl-RegularItalic-WebXL.woff2';
import './suisse-intl/SuisseIntl-SemiBold-WebS.woff2';
import './suisse-intl/SuisseIntl-SemiBold-WebM.woff2';
import './suisse-intl/SuisseIntl-SemiBold-WebXL.woff2';
import './suisse-intl/SuisseIntl-SemiBoldItalic-WebS.woff2';
import './suisse-intl/SuisseIntl-SemiBoldItalic-WebM.woff2';
import './suisse-intl/SuisseIntl-SemiBoldItalic-WebXL.woff2';
import './suisse-intl/SuisseIntl-ThinItalic-WebS.woff2';
import './suisse-intl/SuisseIntl-ThinItalic-WebM.woff2';
import './suisse-intl/SuisseIntl-ThinItalic-WebXL.woff2';
import './suisse-intl/SuisseIntl-Thin-WebS.woff2';
import './suisse-intl/SuisseIntl-Thin-WebM.woff2';
import './suisse-intl/SuisseIntl-Thin-WebXL.woff2';

import './fondamento/Fondamento-Regular.ttf';
import './fondamento/Fondamento-Italic.ttf';
