import Vue from 'vue';

export default function() {
  Vue.component('accommodation', {
    props: {
      guarantee: {
        type: Boolean,
        default: false
      },
      spouse: {
        type: Boolean,
        default: false
      },
      hotel: {
        type: [String, Boolean],
        default: false
      }
    },
    data() {
      return {
        showGuarantee: false,
        cardInfo: false,
        deferTimer: false,
        showHotel: false,
        showSpouse: false
      };
    },

    mounted() {
      if (this.guarantee) {
        this.showGuarantee = true;
      }

      if (this.hotel) {
        this.showHotel = this.hotel;
      }

      if (this.spouse) {
        this.showSpouse = this.spouse;
      }
    },

    methods: {
      toggleGuarantee(value, hotel = false) {
        this.showGuarantee = value;
        if (hotel && value) {
          this.showHotel = hotel;
        } else {
          this.showHotel = false;
        }
      },

      toggleSpouse(value) {
        this.showSpouse = value;
      },

      checkCard(e) {
        if (this.deferTimer) {
          clearTimeout(this.deferTimer);
        }

        this.deferTimer = setTimeout(() => {
          this.queryCard(e.target.value);
        }, 500);
      },

      async queryCard(number) {
        if (!number) {
          this.cardInfo = false;
        } else {
          const response = await fetch(`https://lookup.binlist.net/${number}`);
          this.cardInfo = await response.json();
        }
      }
    }
  });
}
