var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "twitter-tweet" }, [
    !_vm.loaded
      ? _c(
          "div",
          {
            staticClass:
              "flex items-center py-2 space-x-2 text-sm text-opacity-70 text-gray-dark",
          },
          [
            _c("img", {
              attrs: {
                src: require("../../../images/icons/twitter_blue.svg"),
                alt: "Twitter logo",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("Loading tweet...")]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { ref: "tweetContainer" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }