<template>
  <a :href="link" class="text-center">
    <div class="flex justify-center items-center">
      <div v-if="days > 0" class="item">
        <div class="digit-group">
          <div v-for="digit in daysString" :key="digit" class="digit-box">
            {{ digit }}
          </div>
        </div>
        <div class="format">Days</div>
      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="4"
        height="14"
        viewBox="0 0 4 14"
        fill="none"
      >
        <path
          d="M1.8002 3.99999C2.68385 3.99999 3.4002 3.28364 3.4002 2.39999C3.4002 1.51633 2.68385 0.799988 1.8002 0.799988C0.91654 0.799988 0.200195 1.51633 0.200195 2.39999C0.200195 3.28364 0.91654 3.99999 1.8002 3.99999Z"
          fill="white"
        />
        <path
          d="M1.8002 13.6C2.68385 13.6 3.4002 12.8836 3.4002 12C3.4002 11.1163 2.68385 10.4 1.8002 10.4C0.91654 10.4 0.200195 11.1163 0.200195 12C0.200195 12.8836 0.91654 13.6 1.8002 13.6Z"
          fill="white"
        />
      </svg>

      <div class="item">
        <div class="digit-group">
          <div v-for="digit in hoursString" :key="digit" class="digit-box">
            {{ digit }}
          </div>
        </div>
        <div class="format">Hours</div>
      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="4"
        height="14"
        viewBox="0 0 4 14"
        fill="none"
      >
        <path
          d="M1.8002 3.99999C2.68385 3.99999 3.4002 3.28364 3.4002 2.39999C3.4002 1.51633 2.68385 0.799988 1.8002 0.799988C0.91654 0.799988 0.200195 1.51633 0.200195 2.39999C0.200195 3.28364 0.91654 3.99999 1.8002 3.99999Z"
          fill="white"
        />
        <path
          d="M1.8002 13.6C2.68385 13.6 3.4002 12.8836 3.4002 12C3.4002 11.1163 2.68385 10.4 1.8002 10.4C0.91654 10.4 0.200195 11.1163 0.200195 12C0.200195 12.8836 0.91654 13.6 1.8002 13.6Z"
          fill="white"
        />
      </svg>

      <div class="item">
        <div class="digit-group">
          <div v-for="digit in minutesString" :key="digit" class="digit-box">
            {{ digit }}
          </div>
        </div>
        <div class="format">Minutes</div>
      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="4"
        height="14"
        viewBox="0 0 4 14"
        fill="none"
      >
        <path
          d="M1.8002 3.99999C2.68385 3.99999 3.4002 3.28364 3.4002 2.39999C3.4002 1.51633 2.68385 0.799988 1.8002 0.799988C0.91654 0.799988 0.200195 1.51633 0.200195 2.39999C0.200195 3.28364 0.91654 3.99999 1.8002 3.99999Z"
          fill="white"
        />
        <path
          d="M1.8002 13.6C2.68385 13.6 3.4002 12.8836 3.4002 12C3.4002 11.1163 2.68385 10.4 1.8002 10.4C0.91654 10.4 0.200195 11.1163 0.200195 12C0.200195 12.8836 0.91654 13.6 1.8002 13.6Z"
          fill="white"
        />
      </svg>

      <div class="item">
        <div class="digit-group">
          <div v-for="digit in secondsString" :key="digit" class="digit-box">
            {{ digit }}
          </div>
        </div>
        <div class="format">Seconds</div>
      </div>
    </div>

    <div class="label">{{ label }}</div>
  </a>
</template>

<script>
export default {
  props: {
    link: String,
    label: String,
    date: Number
  },

  data() {
    return {
      interval: 0,
      days: 0,
      minutes: 0,
      hours: 0,
      seconds: 0
    };
  },

  computed: {
    daysString() {
      return String(this.days).padStart(2, '0').split('');
    },
    hoursString() {
      return String(this.hours).padStart(2, '0').split('');
    },
    minutesString() {
      return String(this.minutes).padStart(2, '0').split('');
    },
    secondsString() {
      return String(this.seconds).padStart(2, '0').split('');
    }
  },

  mounted() {
    // Update the countdown every 1 second
    this.timerCount();
    this.interval = setInterval(() => this.timerCount(), 1000);
    document.querySelector('body').classList.add('pt-30');
    document.querySelector('body').classList.add('md:pt-20');
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    timerCount() {
      let now = new Date().getTime();
      let timeLeft = this.date - now;

      if (timeLeft > 0) {
        this.calcTime(timeLeft);
      } else {
        clearInterval(this.interval);
      }
    },

    calcTime(time) {
      this.days = Math.floor(time / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((time % (1000 * 60)) / 1000);
    }
  }
};
</script>

<style scoped lang="scss">
.item {
  @apply flex flex-col items-center;
  padding: 0.5rem;
}

.digit-group {
  display: flex;
}

.digit-box {
  border-radius: 3.2px;
  background: #fff;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.5);
  color: #08284f;
  font-family: "Suisse Int'l", sans-serif;
  font-size: 25.6px;
  font-style: normal;
  font-weight: 600;
  line-height: 30.4px; /* 118.75% */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;

  &:not(:last-child) {
    margin-right: 0.25rem;
  }
}

.format {
  margin-top: 5px;
  color: white;
  text-align: center;
  font-size: 6.4px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  opacity: 0.6;
}

.label {
  color: var(--Cream, #f9f9f4);
  text-align: center;
  font-family: "Suisse Int'l", sans-serif;
  font-size: 6.4px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
</style>
