import Vue from 'vue';
import vClickOutside from 'v-click-outside';

export default function() {
  Vue.component('header-component', {
    directives: {
      clickOutside: vClickOutside.directive
    },
    data: function() {
      return {
        mobileMenuOpened: false
      };
    },

    mounted() {
      window.addEventListener('resize', this.onResize);
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    },

    methods: {
      onResize: function() {
        if (window.innerWidth >= 1200 && this.mobileMenuOpened) {
          this.mobileMenuOpened = false;
        }
      }
    }
  });
}
