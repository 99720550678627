import Vue from 'vue';

export default function() {
  Vue.component('un-antisemitism-stream', () =>
    import(/* webpackChunkName: "un-antisemitism-stream" */ './Stream')
  );
  Vue.component('un-antisemitism-speakers', () =>
    import(/* webpackChunkName: "un-antisemitism-speakers" */ './Speakers')
  );
}
