var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        ref: "content",
        staticClass: "overflow-hidden transition-all duration-500",
        class: _vm.contentClasses,
        style: { "max-height": _vm.maxHeight },
        on: { transitionend: _vm.onTransitionComplete },
      },
      [_vm._t("content")],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _vm.collapsed
          ? _vm._t("open-trigger", null, { toggle: _vm.toggle })
          : _vm._t("close-trigger", null, { toggle: _vm.toggle }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }