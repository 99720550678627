export default function copyClipboard() {
  document.addEventListener('click', (e) => {
    let parent = e.target.parentElement;
    if (parent == null) return;

    if (parent.id == 'url_copy') {
      e.preventDefault();
      let link = `https://www.worldjewishcongress.org${parent.value}`;
      navigator.clipboard.writeText(link);

      let data = parent.getAttribute('data');
      let label = document.getElementById(`custom-tooltip ${data}`);

      label.style.display = 'block';
      setTimeout(function () {
        label.style.display = 'none';
      }, 2000);
    }
  });
}
