<template>
  <div class="container pb-10">
    <div class="flex justify-between rounded-sm">
      <div class="flex bg-white">
        <ElevateFilter
          v-model="activeCountry"
          :options="options"
          initial-value="Select All Countries"
          placeholder="Select All Countries"
          class="w-full md:w-auto xl:mr-8 mena-filter z-30 relative"
        />
      </div>
    </div>

    <div class="pt-12">
      <div v-if="visibleListItems.length" 
        class="grid grid-cols-12 gap-8">
        <ListItem
          v-for="representative in visibleListItems"
          :key="representative._id"
          :representative="representative"
        >
        </ListItem>
      </div>
      <p v-else class="mt-8 text-primary text-2xl font-medium">
        No items found by this country!
      </p>
    </div>

    <button 
      class="load-more-btn"
      v-if="showLoadMore" 
      @click="loadMore"
      >
      Load More
    </button>
  </div>
</template>

<script>
import ListItem from './ListItem.vue';
import ElevateFilter from './ElevateFilter.vue';

export default {
  components: { ListItem, ElevateFilter },

  props: {
    representatives: { required: true, type: Array }
  },

  data() {
    return {
      loading: false,
      selectedOption: null,
      activeCountry: 'Select All Countries',
      displayCount: 21,
    };
  },

  computed: {
    options() {
      let options = this.representatives.map((x) => x.country.names[0]),
         uniqueOptions = options.filter((item, i, arg) => arg.indexOf(item) === i);
      return uniqueOptions.sort();
    },

    visibleListItems() {
      let copyList = JSON.stringify(this.representatives),
        parsedList = JSON.parse(copyList);

      let fileterItems = parsedList.filter(
        (item) => this.matchesCurrentCountry(item)
      )
      return fileterItems.slice(0, this.displayCount);
    },

    showLoadMore() {
      return this.displayCount < this.representatives.length;
    },
  },

  methods: {
    matchesCurrentCountry(item) {
      return (
        this.activeCountry === 'Select All Countries' ||
        item.country.names[0] == this.activeCountry
      );
    },

    loadMore() {
      this.displayCount += 6;
    },
  }
};
</script>

<style scoped lang="scss">
.load-more-btn {
  @apply bg-primary text-white text-lg font-600 font-suisse-intl py-3 px-28 mt-12 mb-16 justify-center mx-auto flex;

  @screen lg {
    @apply mb-32;

    width: 352px;
  }
}
</style>
