import Vue from 'vue';
import vClickOutside from 'v-click-outside';

export default function() {
  Vue.component('conspiracy-dropdown', {
    directives: {
      clickOutside: vClickOutside.directive
    },
    data: function() {
      return {
        mobileMenuOpened: false
      };
    },

    methods: {
      onClickOutside() {
        this.mobileMenuOpened = false;
      }
    }
  });
}
