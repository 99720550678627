import Vue from 'vue';

export default function() {
  Vue.component('accordion', {
    data: function() {
      return {};
    },

    methods: {
      toggleActive(event) {
        const classList = event.target.parentElement.classList;

        if (classList.contains('accordion-item-title') || classList.contains('accordion-item')) {
          this.$el.querySelectorAll('.accordion-item').forEach((el) => {
            if (el !== event.currentTarget) {
              el.classList.remove('active');
            } else {
              event.currentTarget.classList.toggle('active');
            }
          });
        }
      }
    }
  });
}
