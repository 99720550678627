import Vue from 'vue';

import headerComponent from './header-component';
import offices from './offices';
import igrcMap from './igrc-map';
import accordion from './accordion';
import accommodation from './accommodation';
import homeVideo from './home-video';
import heroImage from './hero-image';
import popup from './popup-box';
import highHolidays from './high-holidays/index';
import speakersBureau from './speakers-bureau/index';
import halle from './halle/index';
import unAntisemitism from './un-antisemitism/index';
import pledgeAgainstAntisemitism from './pledge-against-antisemitism/index';
import IhraSwiper from './ihra-new-page/ihra-swiper';
import MenaList from './mena/index';
import conspiracyDropdown from './conspiracy-dropdown';
import dropdown from './dropdown';
import bridgeMap from './leadership-bridge-map';
import campusDuringWar from './campus_during_war/index';
import elevateRepresentative from './elevate/index';


export default function() {
  Vue.component('communities', () =>
    import(/* webpackChunkName: "communities" */ './Communities.vue')
  );
  Vue.component('tooltip-trigger', () =>
    import(/* webpackChunkName: "communities" */ './TooltipTrigger.vue')
  );
  // Vue.component('campus-during-war-universities', () =>
  //   import(/* webpackChunkName: "communities" */ './campus_during_war/Universities.vue')
  // );
  headerComponent();
  offices();
  igrcMap();
  bridgeMap();
  accordion();
  accommodation();
  dropdown();
  homeVideo();
  heroImage();
  popup();
  highHolidays();
  halle();
  speakersBureau();
  unAntisemitism();
  pledgeAgainstAntisemitism();
  IhraSwiper();
  MenaList();
  conspiracyDropdown();
  campusDuringWar();
  elevateRepresentative();
}
