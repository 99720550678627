var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showStream
    ? _c("div", [_vm._t("stream")], 2)
    : _c("div", { staticClass: "halle-timer" }, [
        _c(
          "div",
          {
            staticClass: "flex flex-col justify-center items-center text-white",
          },
          [
            _c(
              "div",
              { staticClass: "uppercase text-xl sm:text-2xl opacity-40" },
              [_vm._v("\n      stream starting\n    ")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "halle-timer-tick" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm._f("pad")(_vm.days)) + "\n        "
                ),
                _c("div", { staticClass: "halle-timer-unit" }, [
                  _vm._v("days"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "halle-timer-separator" }, [
                _vm._v(":"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "halle-timer-tick" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm._f("pad")(_vm.hours)) + "\n        "
                ),
                _c("div", { staticClass: "halle-timer-unit" }, [
                  _vm._v("hours"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "halle-timer-separator" }, [
                _vm._v(":"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "halle-timer-tick" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("pad")(_vm.minutes)) +
                    "\n        "
                ),
                _c("div", { staticClass: "halle-timer-unit" }, [
                  _vm._v("minutes"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "halle-timer-separator" }, [
                _vm._v(":"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "halle-timer-tick" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("pad")(_vm.seconds)) +
                    "\n        "
                ),
                _c("div", { staticClass: "halle-timer-unit" }, [
                  _vm._v("seconds"),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("add-to-calendar", { attrs: { time: _vm.streamTime } }),
          ],
          1
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }