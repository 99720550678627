<script>
export default {
  provide() {
    return {
      staticLabelsScope: this.scope
    };
  },

  props: {
    scope: { required: true, type: String }
  },

  render(createElement) {
    return this.$slots.default;
  }
};
</script>
