import Vue from 'vue';

import StaticLabelScopeProvider from './StaticLabelScopeProvider';
import StaticLabel from './StaticLabel';
import StaticLabelInjector from './StaticLabelInjector';
import StaticLabelRegistry from './StaticLabelRegistry';

Vue.component('static-label-scope-provider', StaticLabelScopeProvider);
Vue.component('static-label', StaticLabel);
Vue.component('static-label-injector', StaticLabelInjector);
Vue.component('static-label-registry', StaticLabelRegistry);
