<template>
  <div>
    <div
      class="reveal-wrapper"
      :style="{ 'max-height': isActive ? '10000px' : maxHeight }"
    >
      <div class="reveal-inner">
        <slot />
      </div>

      <div v-if="!isActive" role="presentation" class="reveal-gradient"></div>
    </div>

    <button type="button" class="reveal-button mt-4" @click="isActive = !isActive">
      Read {{ isActive ? 'Less' : 'More' }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    maxHeight: { type: String, default: '180px' }
  },

  data() {
    return {
      isActive: false
    };
  }
};
</script>

<style scoped>
.reveal-wrapper {
  transition: max-height 0.3s ease-in-out;

  position: relative;
  overflow: hidden;
}

.reveal-gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3rem;
  background: linear-gradient(rgba(255, 255, 255, 0), white);
}

.bg-gold-light .reveal-gradient {
  background: linear-gradient(theme('colors.gold-light')00, theme('colors.gold-light'));
}

.bg-primary .reveal-gradient {
  background: linear-gradient(theme('colors.primary')00, theme('colors.primary'));
}
</style>
