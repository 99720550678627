export default function Scroller() {
  const facts = document.querySelectorAll('.fact');

  facts.forEach((fact) => {
    const href = fact.getAttribute('href');
    const section = document.querySelector(href);

    fact.addEventListener('click', (e) => {
      e.preventDefault();

      section.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
    });
  });
}
