var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.showImage
      ? _c(
          "div",
          {
            ref: "modal",
            staticClass: "bg-black bg-opacity-60 fixed inset-0 z-50",
            on: {
              click: function ($event) {
                return _vm.closeImg()
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "container flex flex-col lg:flex-row justify-center w-11/12 m-auto pt-32",
              },
              [
                _c("div", {
                  staticClass: "modal-image-mini-swiper",
                  attrs: { id: "image-target" },
                  domProps: { innerHTML: _vm._s(_vm.imgContent.outerHTML) },
                }),
              ]
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "custom-swiper-container mini-swiper-img" }, [
      _c(
        "div",
        {
          ref: "rootEl",
          staticClass: "ec-zagreb-swiper swiper-container",
          attrs: { "manual-init": "" },
        },
        [
          _c(
            "div",
            { staticClass: "swiper-wrapper mr-8" },
            _vm._l(_vm.items, function (item) {
              return _c(
                "div",
                { key: item.id, staticClass: "swiper-slide mini-swiper-img" },
                [
                  _c("img", {
                    staticClass: "mini-swiper-img",
                    attrs: { srcset: item },
                    on: {
                      click: function ($event) {
                        return _vm.handleImgClick($event)
                      },
                    },
                  }),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          {
            staticClass:
              "swiper-button-prev swiper-nav-btn mt-0 js-swiper-prev w-12 h-12 fix-left",
            staticStyle: { background: "rgba(8, 40, 79, 0.24)", top: "44%" },
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "16",
                  height: "16",
                  viewBox: "0 0 16 16",
                  fill: "none",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M8 0L9.41 1.41L3.83 7H16V9H3.83L9.41 14.59L8 16L0 8L8 0Z",
                    fill: "white",
                  },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "swiper-button-next swiper-nav-btn mt-0 js-swiper-next h-12 w-12 fix-right",
            staticStyle: { background: "rgba(8, 40, 79, 0.24)", top: "44%" },
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "16",
                  height: "16",
                  viewBox: "0 0 16 16",
                  fill: "none",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z",
                    fill: "white",
                  },
                }),
              ]
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }