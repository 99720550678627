export default {
  state: {
    highlightStaticLabels: false,
    highlightDeepLinks: false
  },

  toggleStaticLabelsHighlight(value = !this.state.highlightStaticLabels) {
    this.state.highlightStaticLabels = value;
  },

  toggleDeepLinksHighlight(value = !this.state.highlightDeepLinks) {
    this.state.highlightDeepLinks = value;

    if (typeof window.highlightDeepLinks === 'function' && value) {
      window.highlightDeepLinks();
    }
    if (typeof window.stopHighlightingDeepLinks === 'function' && !value) {
      window.stopHighlightingDeepLinks();
    }
  }
};
