var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("img", {
    style: _vm.styles,
    attrs: {
      src: _vm.src,
      height: _vm.safeHeight,
      width: _vm.safeWidth,
      alt: _vm.safeAlt,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }