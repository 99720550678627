import Vue from 'vue';
import vClickOutside from 'v-click-outside';

export default function() {
  Vue.component('dropdown', {
    directives: {
      clickOutside: vClickOutside.directive
    },
    data: function() {
      return {
        menuOpened: false
      };
    },

    methods: {
      onClickOutside() {
        this.menuOpened = false;
      },

      inputHadnler(event) {
        let items = this.$el.querySelectorAll('.dropdown-item'),
          searchValue = event.target.value.toUpperCase();

        Array.from(items, (item) => {
          let value = item.textContent || item.innerText;

          if (value.toUpperCase().indexOf(searchValue) > -1) {
            item.style.display = "";
          } 
          else {
            item.style.display = "none";
          }
        })
      }
    }
  });
}
