<template>
  <button class="btn btn-white uppercase mt-14 mx-4 text-sm sm:text-lg">
    Add to your calendar
    <div class="atc-dropdown">
      <a :href="links.ics" target="_blank" rel="nofollow noreferrer">Apple</a>
      <a :href="links.google" target="_blank" rel="nofollow noreferrer">
        Google
      </a>
      <a :href="links.office365" target="_blank" rel="nofollow noreferrer">
        Office 365
      </a>
      <a :href="links.outlook" target="_blank" rel="nofollow noreferrer">
        Outlook
      </a>
    </div>
  </button>
</template>

<script>
import { google, outlook, office365, ics } from 'calendar-link';

export default {
  props: {
    time: { required: true, type: Number }
  },

  computed: {
    event() {
      return {
        title: 'The Yom Kippur Attack - Halle: One Year After',
        description:
          'Last Yom Kippur, Jews around the world were shocked to learn of an unfolding attack by a heavily armed, far-right extremist on the Synagogue in Halle, Germany. Unable to gain access, the gunman then turned on a Kebab restaurant. Two people lost their lives. Join WJC representatives and Commissioner for Jewish Life in Germany and the Fight against Anti-Semitism, Dr. Felix Klein, who will be in Halle to commemorate this attack.',
        start: new Date(this.time),
        duration: [30, 'minutes'],
        location: window.location.href
      };
    },

    links() {
      return {
        google: google(this.event),
        ics: ics(this.event),
        office365: office365(this.event),
        outlook: outlook(this.event)
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.btn {
  @apply relative;

  &:hover {
    .atc-dropdown {
      @apply flex;
    }
  }
}

.atc-dropdown {
  @apply absolute z-20 left-0;
  @apply hidden;
  @apply rounded;
  @apply w-full;
  @apply flex-col justify-start;
  @apply bg-white;
  @apply shadow-md;

  a {
    @apply block;
    @apply px-8 py-6;

    &:hover {
      @apply bg-white-hover;
    }
  }
}
</style>
