export default function setupDisabledButton() {
  document.querySelectorAll('[data-depend-on]').forEach((button) => {
    if (!button.dataset.dependOn) { return; }

    let input = document.querySelector(button.dataset.dependOn);

    changeState(button, input);
    input.addEventListener('change', () => changeState(button, input));
  });
}

function changeState(button, input) {
  button.disabled = !input.checked;
}
