import Vue from 'vue';

export default function() {
  Vue.component('ihra-swiper', {
    data: function() {
      return {
        activeCard: '',
        nextBtnDisabled: false,
        prevBtnDisabled: false
      };
    },
    watch: {
      activeCard() {
        if (this.activeCard) {
          let articleComponent = document.querySelector(`.article-modal[card-id='${this.activeCard}']`).firstElementChild;
          articleComponent.style.top = (window.scrollY  + 100) + 'px';
          articleComponent.style.minHeight = articleComponent.scrollHeight + 'px';

          let articleId = articleComponent.parentElement.getAttribute('card-id');
          window.location.href = `#${articleId}`;

          this.determineBtnDisabling(articleComponent.parentElement);
        }
      }
    },
    methods: {
      nextCard(articleId) {
        let articleComponent = document.querySelector(`.article-modal[card-id='${articleId}']`),
          nextArticleId =  articleComponent.nextElementSibling.getAttribute('card-id')

        if (articleComponent.nextElementSibling != null) {
          this.activeCard = nextArticleId;
        }

        this.determineBtnDisabling(articleComponent);
      },
      previousCard(articleId) {
        let articleComponent = document.querySelector(`.article-modal[card-id='${articleId}']`),
          prevArticleId =  articleComponent.previousElementSibling.getAttribute('card-id');

        if (articleComponent.previousElementSibling != null) {
          this.activeCard = prevArticleId;
        }

        this.determineBtnDisabling(articleComponent)
      },
      determineBtnDisabling(article) {
        if (article.nextElementSibling == null) {
          this.nextBtnDisabled = true;
        }
        else {
          this.nextBtnDisabled = false;
        }

        if(article.previousElementSibling.classList.contains('ihra-new-page-swiper-container')) {
          this.prevBtnDisabled = true;
        }
        else { 
          this.prevBtnDisabled = false;
        }
      } 
    } 
  });
}
