<template>
  <div class="col-span-12 lg:col-span-4" >
    <a 
      class="media-element relative"
      v-if="representative.thumbnail_image && representative.link"
      :href="representative.link"
      target="_blank"
    >
      <ImgixImage
        :image="representative.thumbnail_image"
        :width="362"
        :height="239"
        class="inline-block representative-img"
      />
      <div class="hover-element">
        <svg class="yellow-arrow" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M64 0V64L0 0H64Z" fill="#F5DE4C"/>
          <path d="M38.881 13.3137L38.881 15.3078L46.7794 15.3148L38.1739 23.9203L39.5881 25.3345L48.1936 16.729L48.1865 24.6274H50.1947V13.3137H38.881Z" fill="#1C2B59"/>
        </svg>
      </div>
    </a>
    <div v-else class="youtube-video-width">
      <youtube-video 
        :video='representative.video_link'
        :width="362"
        :height="239"
        class="h-full"
      >
      </youtube-video>
    </div>
    <div class="mt-4 mb-2 font-400 font-suisse-intl text-lg flex items-center">
      {{ representative.member  }}
      <img
        :src="flag"
        :alt="representative.country.names[0]"
        class="h-4 rounded m-2"
      />
    </div>

    <h4
      class="text-lg text-primary font-suisse-intl font-600"
    >
      {{ representative.title }}
    </h4>
  </div>
</template>

<script>
import ImgixImage from '../../../admin/asset-manager/components/ImgixImage';
import YoutubeVideo from '../youtube-video.vue'

const flags = require.context(
  '../../../../assets/images/country_flags/',
  false,
  /.*\.png$/
);

export default {
  components: { ImgixImage, YoutubeVideo },

  props: {
    representative: { required: true, type: Object }
  },
  
  methods: {
    youtube_parser(url){
      let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/,
      match = url.match(regExp);

      return ( match&&match[7].length==11 ) ? match[7] : false;
    },

   
  },
  computed: {
    youtubeTumbnail() {
      console.log(this.representatives)

      let videoId = this.youtube_parser(this.representative.video_link),
        thumbnail= `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`

      return thumbnail;
    },

    flag() {
      return flags(`./${this.representative.country.code.toLowerCase()}.png`);
    }
  },
  mounted() {
    let iframe = document.querySelectorAll('iframe');

    Array.from(iframe).forEach((e) => {
      e.style.width = '100%';
      e.style.minHeight = '239px';
    })

  }
};
</script>

<style lang="scss">

  .hover-element {
    @apply opacity-0 absolute top-0 right-0 w-full;
    @apply transform transition-all duration-150;
    background-image: linear-gradient(0deg, rgba(41, 91, 165, 0.50) 0%, rgba(41, 91, 165, 0.50) 100%);
    min-height: 239px;

    .yellow-arrow {
      @apply absolute top-0 right-0;
    }

    &:hover {
      @apply opacity-100;
    }
  }
.youtube-video-width {
  max-width: 362px;

  iframe{
    width: 100%;
    height: 239px;
  }
}

.representative-img {
  min-height: 239px;
}
.iframe-size {
  iframe {
    widows: 100%;
    min-height: 239px;
  }
  
  .youtube-iframe {
    width: 100%;
    height: 239px;
  }
}

</style>
