var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClickOutside,
          expression: "onClickOutside",
        },
      ],
      staticClass: "elevate-representative-filter-selector",
      on: {
        click: function ($event) {
          $event.preventDefault()
          _vm.showDropDown = !_vm.showDropDown
        },
      },
    },
    [
      _c("span", [_vm._v(_vm._s(_vm.stateValue))]),
      _vm._v(" "),
      _c(
        "svg",
        {
          staticClass: "ml-auto",
          attrs: {
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M6 9L12 15L18 9",
              stroke: "#101828",
              "stroke-width": "2",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _vm.showDropDown
        ? _c(
            "ul",
            _vm._l(_vm.allOptions, function (option, i) {
              return _c("li", { key: i }, [
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.select(option)
                      },
                    },
                  },
                  [_vm._v("\n        " + _vm._s(option) + "\n      ")]
                ),
              ])
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }