<template>
  <div>
    <div class="selected-video ec-zagreb-video mb-8" v-for="youtubeItem in youtubeItems" :key="youtubeItem.id">
      <youtube-video 
        :video='youtubeItem.youtubeId' 
        :class="{ hidden: selectedVideoId != youtubeItem.youtubeId }">
      </youtube-video>
    </div>
   
    <div class="custom-swiper-container mt-8">
      <div ref="rootEl" class="ec-zagreb-swiper swiper-container" manual-init>
        <div class="swiper-wrapper ec-zagreb-slide mr-8">
          <div v-for="youtubeItem in youtubeItems" :key="youtubeItem.id" class="swiper-slide mr-8">
            <button @click="selectVideo(youtubeItem.youtubeId)">
              <img :src="youtubeItem.thumbnail" class="h-full" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import YoutubeVideo from './youtube-video.vue';

const DEFAULT_BREAKPOINTS = {
  576: { slidesPerView: 3 },
  992: { slidesPerView: 'auto' },
};

const DEFAULT_CONFIG = {
  watchOverflow: true,
  simulateTouch: true,
  slidesPerView: 'auto',
  spaceBetween: 32,
};

export default {
  props: {
    items: { required: true}
  },

  components: { YoutubeVideo },

  data() {
    return {
      swiper: null,
      youtubeItems: [],
      selectedVideoId: '',
      default: () => {
        DEFAULT_BREAKPOINTS;
      }
    };
  },

  methods: {
    youtube_parser(url){
      let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/,
      match = url.match(regExp);

      return ( match&&match[7].length==11 ) ? match[7] : false;
    },
    selectVideo(id) {
      this.selectedVideoId = id;
    }
  },

  mounted() {
    Array.from(JSON.parse(this.items)).forEach(item => {
      let videoId = this.youtube_parser(item);

      let youtubeData = {
        youtubeId: item,
        thumbnail: `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`
      };

      this.youtubeItems.push(youtubeData);
    });

    this.swiper = new Swiper(this.$refs.rootEl, {
      ...DEFAULT_CONFIG,
      navigation: {
        nextEl: this.$el.querySelector('.js-swiper-next'),
        prevEl: this.$el.querySelector('.js-swiper-prev')
      },
      breakpoints: this.breakpoints,
      ...this.config
    });

    this.selectedVideoId = this.youtubeItems[0].youtubeId;
  },
};
</script>
