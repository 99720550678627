export function parseVideos(targetContainer = document.body) {
  targetContainer.querySelectorAll('.fb-video').forEach((video) => {
    if (video.querySelector('iframe')) {
      return; // Already initialized
    }

    FB.XFBML.parse(video.parentNode);
  });
}

export function findVideoPlaceholders(targetContainer = document.body) {
  return targetContainer.querySelectorAll('.fb-video');
}

export function insertSDK({ always = false } = {}) {
  if (!always && !findVideoPlaceholders().length) {
    return;
  }

  if (!document.querySelector('#fb-root')) {
    return;
  }

  const rootDiv = document.createElement('div');
  rootDiv.setAttribute('id', 'fb-root');

  const sdkScript = document.createElement('script');
  sdkScript.setAttribute('async', '');
  sdkScript.setAttribute('defer', '');
  sdkScript.setAttribute('crossorigin', 'anonymous');
  sdkScript.setAttribute('src', 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v8.0');
  sdkScript.setAttribute('nonce', 'XX4YcdMI');

  document.body.appendChild(rootDiv);
  document.body.appendChild(sdkScript);
}
