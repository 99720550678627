import Vue from 'vue';
import { initDeepLinks } from './deep-links';

Vue.component('settings-panel', () =>
  import(/* webpackChunkName: "admin-settings-panel" */ './settings-panel/SettingsPanel')
);

// Statics - Editable texts
require('../static-labels/index');

initDeepLinks();
