var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container pb-10" }, [
    _c("div", { staticClass: "flex justify-between rounded-sm" }, [
      _c(
        "div",
        { staticClass: "flex w-full bg-white" },
        [
          _c("Multiselect", {
            staticClass:
              "w-full outline-none text-blue-002B48 font-suisse-intl text-base",
            attrs: {
              options: _vm.options,
              allowEmpty: false,
              placeholder: "Choose university",
            },
            model: {
              value: _vm.selectedOption,
              callback: function ($$v) {
                _vm.selectedOption = $$v
              },
              expression: "selectedOption",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "pt-12" }, [
      _vm.visibleUniversities.length
        ? _c(
            "div",
            _vm._l(_vm.visibleUniversities, function (university) {
              return _c("University", {
                key: university._id,
                attrs: { university: university },
              })
            }),
            1
          )
        : _c("p", { staticClass: "mt-8 text-primary text-2xl font-medium" }, [
            _vm._v("\n      No universities found!\n    "),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }