<template>
  <div class="twitter-tweet">
    <div
      v-if="!loaded"
      class="flex items-center py-2 space-x-2 text-sm text-opacity-70 text-gray-dark"
    >
      <img src="../../../images/icons/twitter_blue.svg" alt="Twitter logo" />
      <span>Loading tweet...</span>
    </div>
    <div ref="tweetContainer" />
  </div>
</template>

<script>
export default {
  props: {
    tweetId: { required: true, type: String }
  },

  data() {
    return {
      loaded: false
    };
  },

  async mounted() {
    await this.$loadScript('https://platform.twitter.com/widgets.js');

    await twttr.widgets.createTweet(this.tweetId, this.$refs.tweetContainer);

    this.loaded = true;
  }
};
</script>
