var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-8" }, [
    _c(
      "h4",
      { staticClass: "mb-4 capitalize text-primary text-base font-bold" },
      [_vm._v("\n    Filter by date\n  ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "bg-light flex flex-col sm:flex-row lg:flex-col px-2 py-3 rounded-sm",
      },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.state.from,
              expression: "state.from",
            },
          ],
          staticClass: "date-range-selector-input",
          class: { "opacity-40": !_vm.state.from && !_vm.focused.from },
          attrs: {
            type: "date",
            name: "from",
            placeholder: "dd-mm-yyyy",
            max: _vm.state.to,
          },
          domProps: { value: _vm.state.from },
          on: {
            focus: function ($event) {
              _vm.focused.from = true
            },
            blur: function ($event) {
              _vm.focused.from = false
            },
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.state, "from", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "flex-auto text-center text-primary text-sm text-opacity-60",
          },
          [_vm._v("\n      - to -\n    ")]
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.state.to,
              expression: "state.to",
            },
          ],
          staticClass: "date-range-selector-input",
          class: { "opacity-40": !_vm.state.to && !_vm.focused.to },
          attrs: {
            type: "date",
            name: "to",
            placeholder: "dd-mm-yyyy",
            min: _vm.state.from,
          },
          domProps: { value: _vm.state.to },
          on: {
            focus: function ($event) {
              _vm.focused.to = true
            },
            blur: function ($event) {
              _vm.focused.to = false
            },
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.state, "to", $event.target.value)
            },
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }