<template>
  <div>
    <div
      ref="content"
      class="overflow-hidden transition-all duration-500"
      :class="contentClasses"
      :style="{ 'max-height': maxHeight }"
      @transitionend="onTransitionComplete"
    >
      <slot name="content" />
    </div>
    <div>
      <slot v-if="collapsed" name="open-trigger" :toggle="toggle" />
      <slot v-else name="close-trigger" :toggle="toggle" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: { required: true, type: Number },
    contentClasses: { required: false, type: String, default: '' }
  },

  watch: {
    collapsed(value) {
      if (value) {
        // Going to collapse
        this.setHeightToScrollHeight();
        this.$nextTick(() => this.setHeightToPassedValue());
      } else {
        // Going to expand
        this.setHeightToPassedValue();
        this.$nextTick(() => this.setHeightToScrollHeight());
      }
    }
  },

  data() {
    return {
      collapsed: true,
      maxHeight: `${this.height}rem`
    };
  },

  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    },

    setHeightToPassedValue() {
      this.maxHeight = `${this.height}rem`;
    },

    setHeightToScrollHeight() {
      this.maxHeight = `${this.$refs.content.scrollHeight}px`;
    },

    onTransitionComplete() {
      if (this.collapsed) return;

      this.maxHeight = 'none';
    }
  }
};
</script>
