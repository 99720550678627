var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("static-label-injector", {
    attrs: {
      string: _vm.slotText,
      scope: _vm.scope,
      editable: _vm.editable,
      "preloaded-value": _vm.preloadedValue,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var string = ref.string
          return [
            _c(_vm.tag, {
              tag: "component",
              domProps: { innerHTML: _vm._s(_vm.nl2br(string)) },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }