/* eslint no-console:0 */
/* global require:true */

require.context('../images', true);
import '../fonts';
import '../stylesheets';
import initApp from '../javascripts';
import Rails from '@rails/ujs';

document.addEventListener('DOMContentLoaded', () => {
  initApp();
  Rails.start();

  // Mailchimp email retention madness...
  const urlParams = new URLSearchParams(window.location.search);
  const mailchimpField = document.getElementById('mce-EMAIL');

  if (urlParams.has('email') && mailchimpField) {
    mailchimpField.value = urlParams.get('email');
  }
});
