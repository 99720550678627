<template>
  <div>
    <slot
      v-if="!form.isSubmitted"
      name="form"
      :form="form"
      :submit="submit"
    ></slot>
    <slot v-else name="submitted"></slot>
  </div>
</template>

<script>
import axios from '../../common/http/axios';

export default {
  props: {
    scope: { required: true, type: String },
    additionalInfoFields: { required: false, type: Array, default: () => [] }
  },

  data() {
    const additionalInfo = {};
    this.additionalInfoFields.forEach((key) => {
      additionalInfo[key] = null;
    });

    return {
      form: {
        email: '',
        additionalInfo,
        isSubmitted: false,
        isLoading: false,
        errors: {}
      }
    };
  },

  methods: {
    async submit() {
      this.form.isLoading = true;

      try {
        await this.sendToServer();
        this.completed();
      } catch ({ response: { data } }) {
        this.errored(data.errors);
      }
    },

    async sendToServer() {
      return await axios.post(`/api/v1/email_subscriptions/${this.scope}`, {
        email: this.form.email,
        additional_info: this.form.additionalInfo
      });
    },

    completed() {
      this.form.isLoading = false;
      this.form.isSubmitted = true;
      this.form.errors = {};
    },

    errored(errors) {
      this.form.isLoading = false;
      this.$set(this.form, 'errors', errors);
    }
  }
};
</script>

<style></style>
