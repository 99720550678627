import Vue from 'vue';
import dayjs from 'dayjs';

export default function() {
  Vue.component('popup', {
    props: {
      isPopupVisible: Boolean
    },

    data() {
      return {
        country: null,
        popupData: null,
        content: null
      };
    },

    mounted() {
      if (screen.width < 576) {
        return false;
      }

      let lastOpened = localStorage.getItem('lastOpened');
      let hours = dayjs().diff(dayjs(lastOpened), 'hours');

      localStorage.setItem('lastOpened', dayjs());

      //Do NOT show the pop-up if 1 hour is not passed
      if (hours < 1) {
        return false;
      }

      this.country = sessionStorage.getItem('country');

      if (this.country && this.country !== 'null') {
        this._loadPopup(this.country);
      } else {
        this._queryCountry();
      }
    },

    methods: {
      close() {
        this.$emit('change-popup-visible', false);
      },

      open() {
        this.isPopupVisible = true
      },

      videoCLose() {
        this.isPopupVisible = false
      },

      async _queryCountry() {
        try {
          const response = await fetch('/api/geo-country', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
          });

          const countryData = await response.json();

          this.country = countryData.country_code;

          if (countryData.country_code && countryData.country_code !== 'null') {
            sessionStorage.setItem('country', countryData.country_code);
          }

          this._loadPopup();
        } catch (e) {}
      },
      async _loadPopup() {
        const uri = window.location.pathname;

        try {
          const response = await fetch(
            `/popup?country=${this.country}&uri=${uri}`,
            {
              headers: { 'Content-Type': 'application/json' }
            }
          );

          const popupData = await response.json();

          this.popupData = popupData;

          const showDelay = popupData.showAfter * 1000 || 0;
          const hideDelay = popupData.hideAfter * 1000 || 0;

          setTimeout(() => {
            this.$emit('change-popup-visible', true);

            try {
              this.$nextTick(() => {
                window.dataLayer.push({ event: 'optimize.activate' });
              });
            } catch (e) {
              console.log('Optimize.Activate', e);
            }
          }, showDelay);

          if (hideDelay) {
            setTimeout(() => {
              this.$emit('change-popup-visible', false);
            }, hideDelay);
          }
        } catch (e) {}
      }
    }
  });
}
