<template>
  <span>
    <slot :scroll="emitScrollRequest" />
  </span>
</template>

<script>
export default {
  props: {
    anchor: { required: true, type: String }
  },

  methods: {
    emitScrollRequest() {
      BUS.$emit(`anchor.${this.anchor}`);
    }
  }
};
</script>
