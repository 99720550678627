var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a", { staticClass: "text-center", attrs: { href: _vm.link } }, [
    _c("div", { staticClass: "flex justify-center items-center" }, [
      _vm.days > 0
        ? _c("div", { staticClass: "item" }, [
            _c(
              "div",
              { staticClass: "digit-group" },
              _vm._l(_vm.daysString, function (digit) {
                return _c("div", { key: digit, staticClass: "digit-box" }, [
                  _vm._v("\n          " + _vm._s(digit) + "\n        "),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "format" }, [_vm._v("Days")]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "svg",
        {
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            width: "4",
            height: "14",
            viewBox: "0 0 4 14",
            fill: "none",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M1.8002 3.99999C2.68385 3.99999 3.4002 3.28364 3.4002 2.39999C3.4002 1.51633 2.68385 0.799988 1.8002 0.799988C0.91654 0.799988 0.200195 1.51633 0.200195 2.39999C0.200195 3.28364 0.91654 3.99999 1.8002 3.99999Z",
              fill: "white",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M1.8002 13.6C2.68385 13.6 3.4002 12.8836 3.4002 12C3.4002 11.1163 2.68385 10.4 1.8002 10.4C0.91654 10.4 0.200195 11.1163 0.200195 12C0.200195 12.8836 0.91654 13.6 1.8002 13.6Z",
              fill: "white",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "item" }, [
        _c(
          "div",
          { staticClass: "digit-group" },
          _vm._l(_vm.hoursString, function (digit) {
            return _c("div", { key: digit, staticClass: "digit-box" }, [
              _vm._v("\n          " + _vm._s(digit) + "\n        "),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "format" }, [_vm._v("Hours")]),
      ]),
      _vm._v(" "),
      _c(
        "svg",
        {
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            width: "4",
            height: "14",
            viewBox: "0 0 4 14",
            fill: "none",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M1.8002 3.99999C2.68385 3.99999 3.4002 3.28364 3.4002 2.39999C3.4002 1.51633 2.68385 0.799988 1.8002 0.799988C0.91654 0.799988 0.200195 1.51633 0.200195 2.39999C0.200195 3.28364 0.91654 3.99999 1.8002 3.99999Z",
              fill: "white",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M1.8002 13.6C2.68385 13.6 3.4002 12.8836 3.4002 12C3.4002 11.1163 2.68385 10.4 1.8002 10.4C0.91654 10.4 0.200195 11.1163 0.200195 12C0.200195 12.8836 0.91654 13.6 1.8002 13.6Z",
              fill: "white",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "item" }, [
        _c(
          "div",
          { staticClass: "digit-group" },
          _vm._l(_vm.minutesString, function (digit) {
            return _c("div", { key: digit, staticClass: "digit-box" }, [
              _vm._v("\n          " + _vm._s(digit) + "\n        "),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "format" }, [_vm._v("Minutes")]),
      ]),
      _vm._v(" "),
      _c(
        "svg",
        {
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            width: "4",
            height: "14",
            viewBox: "0 0 4 14",
            fill: "none",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M1.8002 3.99999C2.68385 3.99999 3.4002 3.28364 3.4002 2.39999C3.4002 1.51633 2.68385 0.799988 1.8002 0.799988C0.91654 0.799988 0.200195 1.51633 0.200195 2.39999C0.200195 3.28364 0.91654 3.99999 1.8002 3.99999Z",
              fill: "white",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M1.8002 13.6C2.68385 13.6 3.4002 12.8836 3.4002 12C3.4002 11.1163 2.68385 10.4 1.8002 10.4C0.91654 10.4 0.200195 11.1163 0.200195 12C0.200195 12.8836 0.91654 13.6 1.8002 13.6Z",
              fill: "white",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "item" }, [
        _c(
          "div",
          { staticClass: "digit-group" },
          _vm._l(_vm.secondsString, function (digit) {
            return _c("div", { key: digit, staticClass: "digit-box" }, [
              _vm._v("\n          " + _vm._s(digit) + "\n        "),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "format" }, [_vm._v("Seconds")]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }