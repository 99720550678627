var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container pb-10" }, [
    _c("div", { staticClass: "flex justify-between rounded-sm" }, [
      _c(
        "div",
        { staticClass: "flex bg-white" },
        [
          _c("ElevateFilter", {
            staticClass: "w-full md:w-auto xl:mr-8 mena-filter z-30 relative",
            attrs: {
              options: _vm.options,
              "initial-value": "Select All Countries",
              placeholder: "Select All Countries",
            },
            model: {
              value: _vm.activeCountry,
              callback: function ($$v) {
                _vm.activeCountry = $$v
              },
              expression: "activeCountry",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "pt-12" }, [
      _vm.visibleListItems.length
        ? _c(
            "div",
            { staticClass: "grid grid-cols-12 gap-8" },
            _vm._l(_vm.visibleListItems, function (representative) {
              return _c("ListItem", {
                key: representative._id,
                attrs: { representative: representative },
              })
            }),
            1
          )
        : _c("p", { staticClass: "mt-8 text-primary text-2xl font-medium" }, [
            _vm._v("\n      No items found by this country!\n    "),
          ]),
    ]),
    _vm._v(" "),
    _vm.showLoadMore
      ? _c(
          "button",
          { staticClass: "load-more-btn", on: { click: _vm.loadMore } },
          [_vm._v("\n    Load More\n  ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }