<script>
import castArray from 'lodash/castArray';
import get from 'lodash/get';
import set from 'lodash/set';
import settings from '../public/settings-panel/settings';

let _driver = null;
async function driverInstance() {
  if (!_driver) {
    // Import the library on demand
    const {
      default: Driver
    } = await import(/* webpackChunkName: "driver" */ 'driver.js');

    // Instantiate an instance
    _driver = new Driver({
      opacity: 0.3,
      showButtons: false,
      // We turn off the built in shortcuts, because the
      // arrow keys are closing the driver popover otherwise.
      // We define our own ESC handler below
      keyboardControl: false
    });

    // Remove default resize handler and attach
    // custom one that re-attaches the dynamic behaviour
    _driver.window.removeEventListener('resize', _driver.onResize);
    window.addEventListener(
      'resize',
      () => {
        _driver.overlay.refresh();

        // Call the `onResize` option that is introduced by us
        if (_driver.hasHighlightedElement()) {
          get(_driver.getHighlightedElement(), 'options.onResize', () => {})();
        }
      },
      false
    );

    // Listen for ESC key
    window.addEventListener(
      'keyup',
      (e) => {
        if (
          e.keyCode === 27 /* 27 == ESC */ &&
          !document.querySelector(
            '#statics-editor .btn-save[data-loading]'
          ) /* When not loading */
        ) {
          _driver.reset();
        }
      },
      false
    );
  }

  return _driver;
}

export default {
  inject: {
    inheritedScope: {
      from: 'staticLabelsScope',
      default: () => null
    },
    registry: {
      from: 'staticLabelsRegistry',
      default: () => null
    }
  },

  props: {
    string: { required: true, type: String },
    preloadedValue: { required: false, type: String },
    // If not passed an inherited scope will be used (if any)
    scope: { required: false, type: String },
    editable: { required: false, type: Boolean, default: true },
    targetAttribute: { required: false, type: String },
    targetElement: { required: false, type: String }
  },

  data: () => ({
    actualString: null,
    editString: null,
    settings: settings.state
  }),

  watch: {
    string: {
      immediate: true,
      handler(newValue, oldValue) {
        if (!this.registry) {
          return;
        }

        if (oldValue) {
          this.registry.stopWatching(
            oldValue,
            this.actualScope,
            this.onStringUpdated
          );
        }

        if (newValue) {
          this.registry.watch(newValue, this.actualScope, this.onStringUpdated);
        }
      }
    },

    editString() {
      this.injectValueInDOM();
    }
  },

  render(createElement) {
    const slotContent = this.$scopedSlots.default(
      // The static's value is passed down either via:
      // * scoped slot prop when `targetAttribute == null`
      // * injected via JS in the DOM when `targetAttribute != null`
      this.inPropMode ? { string: this.text } : {}
    );

    if (slotContent.length > 1) {
      if (!this.inPropMode && !this.targetElement) {
        throw `The target attribute "${this.targetAttribute}" is ambigious,
          becase the component has many children. Please, use the "targetElement"
          prop to designate the target element!`;
      }

      // If the slot contains more than 1 element wrap in a div
      return createElement(
        'div',
        { class: this.stateClasses, on: { click: this.onClick } },
        slotContent
      );
    }

    this.addClasses(slotContent[0], this.stateClasses);

    // When the highligh is active, do not attach child click events
    const existingClickListeners = this.settings.highlightStaticLabels
      ? []
      : castArray(get(slotContent[0], ['data', 'on', 'click'], []));

    // Attach an event listener
    set(
      slotContent[0],
      ['data', 'on', 'click'],
      [this.onClick].concat(existingClickListeners)
    );

    return slotContent;
  },

  beforeDestroy() {
    if (!this.registry) {
      return;
    }

    this.registry.stopWatching(
      this.string,
      this.actualScope,
      this.onStringUpdated
    );
  },

  computed: {
    inPropMode() {
      return !this.targetAttribute;
    },

    text() {
      return (
        this.editString ||
        this.actualString ||
        this.preloadedValue ||
        this.string
      );
    },

    actualScope() {
      const scope = this.scope || this.inheritedScope;

      if (!scope) {
        throw 'Scopeless static! Either use the :scope props or use StaticScopeProvider';
      }

      return scope;
    },

    stateClasses() {
      return (
        'static-string' +
        (this.settings.highlightStaticLabels && this.editable
          ? ' highlight-active'
          : '')
      );
    }
  },

  methods: {
    onStringUpdated({ value }) {
      this.actualString = value;

      this.injectValueInDOM();
    },

    injectValueInDOM() {
      if (this.inPropMode || !this.$el) {
        return;
      }

      const el = this.targetElement
        ? this.$el.querySelector(this.targetElement)
        : this.$el;
      if (!el) {
        throw 'Cannot find target element!';
      }

      el.setAttribute(this.targetAttribute, this.text);
    },

    addClasses(vNode, classes) {
      vNode.data = vNode.data || {};
      vNode.data.staticClass = [classes, vNode.data.staticClass || ''].join(
        ' '
      );
      return vNode;
    },

    onClick(e) {
      if (!this.settings.highlightStaticLabels || !this.editable) {
        return;
      }
      e.stopImmediatePropagation();
      e.preventDefault();
      e.stopPropagation();

      this.openEditor(e.target.closest('.static-string'));
    },

    async openEditor(element) {
      const self = this;
      const driver = await driverInstance();

      driver.highlight({
        element,
        onHighlighted() {
          self.attachBehaviorToEditor(driver);
        },
        onDeselected() {
          self.editString = null;
        },
        onResize() {
          self.attachBehaviorToEditor(_driver);
        },
        popover: {
          position:
            window.innerWidth >= 800 &&
            window.innerWidth - element.getBoundingClientRect().left <= 450
              ? 'left'
              : 'auto',
          title: 'editor',
          description: `
            <div id="statics-editor">
              <textarea rows="5" name="static-value"></textarea>
              <div class="statics-editor--actions">
                <button class="btn-save">Save</button>
                <button class="btn-close">Cancel</button>
              </div>
            </div>`
        }
      });
    },

    attachBehaviorToEditor(driver) {
      setTimeout(() => {
        let editor = document.querySelector('#statics-editor textarea');
        editor.focus();
        editor.value = this.text;
        editor.addEventListener('input', (e) => {
          this.editString = e.target.value;

          if (driver.hasHighlightedElement()) {
            driver.getHighlightedElement().showStage();
          }
        });

        const saveBtn = document.querySelector('#statics-editor .btn-save');
        const closeBtn = document.querySelector('#statics-editor .btn-close');

        saveBtn.addEventListener('click', async () => {
          saveBtn.innerHTML = 'Saving...';
          saveBtn.setAttribute('disabled', 'disabled');
          saveBtn.setAttribute('data-loading', '1');
          closeBtn.setAttribute('disabled', 'disabled');
          editor.setAttribute('disabled', 'disabled');

          await this.registry.updateValue(
            this.string,
            this.actualScope,
            editor.value
          );

          driver.reset();
        });

        closeBtn.addEventListener('click', () => {
          driver.reset();
        });
      }, 0);
    }
  }
};
</script>

<style lang="scss">
@import 'driver.js/dist/driver.min.css';
@import '../styles/hex/variables';

.driver-fix-stacking {
  z-index: 1 !important;
}
div#driver-highlighted-element-stage {
  box-shadow: 0 0 0 2px #ffffff;
  background-color: transparent !important;
  border-radius: 4px;
}
div#driver-page-overlay {
  opacity: 0.35 !important;
}

div#driver-popover-item {
  min-width: 400px;
  max-width: 400px;
  background-color: rgba(255, 255, 255, 0.85);
  font-family: $font-family;
  border-radius: 4px;

  .driver-popover-tip {
    &.bottom {
      border-color: rgba(255, 255, 255, 0.85) transparent transparent;
    }
    &.top {
      border-color: transparent transparent rgba(255, 255, 255, 0.85);
    }
    &.left {
      border-color: transparent rgba(255, 255, 255, 0.85) transparent
        transparent;
    }
    &.right {
      border-color: transparent transparent transparent
        rgba(255, 255, 255, 0.85);
    }
  }

  .driver-popover-title {
    display: none;
  }

  .driver-popover-description {
    #statics-editor {
      textarea {
        display: block;
        padding: 8px;
        background: white;
        border-radius: 2px;
        color: #505d68;
        width: 100%;
        min-height: auto;
        height: 160px;
        border: none;
        box-shadow: 0 0 3px rgba($color: $gray-500, $alpha: 0.6);
        font-family: $font-family;
        font-size: 14px;
        line-height: 1.5;
        transition: box-shadow 200ms;
        margin: 0 0 16px 0;
        resize: none;

        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px rgba($color: $gray-500, $alpha: 0.4);
        }

        &[disabled] {
          background: $gray-100;
          cursor: not-allowed;
        }
      }
    }
  }

  .statics-editor--actions {
    display: flex;
    text-align: center;

    button {
      font-size: 14px;
      font-weight: normal;
      font-family: $font-family;
      letter-spacing: normal;
      text-transform: none;
      height: unset;
      width: unset;
      line-height: 1;
      margin: 0;
    }

    .btn-save {
      color: $white;
      background: $primary;
      border-radius: 4px;
      padding: 10px 40px;
      border: none;
      opacity: 0.8;
      transition: opacity 200ms;

      &:hover {
        opacity: 1;
      }

      &:focus {
        opacity: 1;
        box-shadow: 0 0 0 2px rgba($color: $primary, $alpha: 0.4);
      }

      &[disabled] {
        background: $gray-500;
        opacity: 1;
        cursor: not-allowed;
      }
    }

    .btn-close {
      background: none;
      border: none;
      color: $gray-500;
      padding: 10px 5px;
      margin-left: 10px;
      border-radius: 4px;

      &:hover {
        text-decoration: underline;
      }

      &:focus {
        box-shadow: 0 0 0 2px rgba($color: $gray-500, $alpha: 0.4);
      }

      &[disabled] {
        opacity: 0.6;
        cursor: not-allowed;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.static-string.highlight-active {
  cursor: pointer;
  transition: outline, background 200ms;
  outline: 1px dashed $yellow !important;

  &:not(.driver-highlighted-element):hover {
    background-color: rgba($color: $yellow, $alpha: 0.2);
  }

  &.driver-highlighted-element {
    outline: 1px solid transparent !important;
  }
}
</style>
