export default function FootnotesScroller(article) {
  let links = article.querySelectorAll('a');

  links.forEach((link) => {
    const href = link.getAttribute('href');

    const regex = /\[([0-9]+)\]/;
    let matches = link.textContent.match(regex);

    if (matches) {
      let [_, number] = matches;

      //Check if link is footnote and add id attribute
      if (href.startsWith('http')) {
        link.setAttribute('id', `footnote-${number}`);

        // If link is in the text and refers to the link in the footnote and add scroll event listener
      } else {
        link.removeAttribute('href');
        link.setAttribute('href', `#footnote-${number}`);

        link.addEventListener('click', (e) => {
          e.preventDefault();
          let location = link.getAttribute('href');
          let section = document.querySelector(location);

          section.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest'
          });
        });
      }
    }
  });
}
