import Vue from 'vue';

export default function() {
  Vue.component('home-video', {
    data: function() {
      return {
        screen: 'xl',
        mqls: [
          window.matchMedia('(min-width: 1200px)'),
          window.matchMedia('(min-width: 992px)'),
          window.matchMedia('(min-width: 768px)')
        ],
        videos: {
          xl: 'https://d10jeryn34m01v.cloudfront.net/video/xl_v2.mp4',
          lg: 'https://d10jeryn34m01v.cloudfront.net/video/lg_v2.mp4',
          md: 'https://d10jeryn34m01v.cloudfront.net/video/md_v2.mp4',
          sm: 'http://d10jeryn34m01v.cloudfront.net/video/sm_v2.mp4'
        }
      };
    },

    computed: {
      video: function() {
        return this.videos[this.screen];
      }
    },

    mounted: function() {
      for (let i = 0; i < this.mqls.length; i++) {
        this.screenTest(this.mqls[i]);
        this.mqls[i].addListener(this.screenTest);
      }
    },

    methods: {
      screenTest: function() {
        if (this.mqls[0].matches) {
          this.screen = 'xl';
        } else if (this.mqls[1].matches) {
          this.screen = 'lg';
        } else if (this.mqls[2].matches) {
          this.screen = 'md';
        } else {
          this.screen = 'sm';
        }

        this.$nextTick(() => {
          document.getElementById('video').load();
        });
      }
    }
  });
}
