var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mb-20" }, [
    _c("div", { staticClass: "grid grid-cols-12 gap-y-8 sm:gap-x-8" }, [
      _c(
        "div",
        { staticClass: "col-span-12 lg:col-span-4" },
        [
          _c("ImgixImage", {
            staticClass: "inline-block",
            attrs: {
              image: _vm.university.thumbnail_image,
              width: 362,
              height: 250,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-span-12 lg:col-span-8" }, [
        _c(
          "h4",
          {
            staticClass:
              "text-xl md:text-7 lg:text-2xl text-primary font-suisse-intl mb-12 font-600",
          },
          [_vm._v("\n        " + _vm._s(_vm.university.name) + "\n      ")]
        ),
        _vm._v(" "),
        _vm.university.description
          ? _c("div", {
              staticClass:
                "pretty-content text-base md:text-lg font-semi-medium font-suisse-works text-gray-dark leading-relaxed",
              domProps: { innerHTML: _vm._s(_vm.university.description) },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.university.statement_link
          ? _c("div", { staticClass: "mt-4" }, [
              _c(
                "a",
                {
                  staticClass: "flex items-center text-blue-002B48 pl-4",
                  staticStyle: {
                    "background-color": "#E0E8EE",
                    width: "fit-content",
                  },
                  attrs: { href: _vm.university.statement_link },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "font-suisse-intl font-600 text-lg lg:text-xl text-center",
                    },
                    [
                      _vm._v(
                        "\n            Read the University's Statement\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "57",
                        height: "57",
                        viewBox: "0 0 57 57",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M18.8562 18.8561L18.8562 22.1795L32.0202 22.1913L17.6777 36.5337L20.0347 38.8908L34.3772 24.5483L34.3654 37.7122H37.7124V18.8561H18.8562Z",
                          fill: "#002B48",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.university.letter_body
          ? _c("div", { staticClass: "mt-6" }, [
              _c(
                "a",
                {
                  staticClass:
                    "flex items-center text-white px-4 space-x-2 py-3",
                  staticStyle: {
                    "background-color": "#002B48",
                    width: "fit-content",
                  },
                  attrs: { href: _vm.letterLink },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "font-suisse-intl font-600 text-lg lg:text-xl text-center",
                    },
                    [
                      _vm._v(
                        "\n            Send the University a Letter\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "32",
                        height: "33",
                        viewBox: "0 0 32 33",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d: "M29.3333 8.56852C29.3333 7.10186 28.1333 5.90186 26.6667 5.90186H5.33334C3.86667 5.90186 2.66667 7.10186 2.66667 8.56852V24.5685C2.66667 26.0352 3.86667 27.2352 5.33334 27.2352H26.6667C28.1333 27.2352 29.3333 26.0352 29.3333 24.5685V8.56852ZM26.6667 8.56852L16 15.2219L5.33334 8.56852H26.6667ZM16 17.9019L5.33334 11.2352V24.5685H26.6667V11.2352L16 17.9019Z",
                          fill: "white",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }