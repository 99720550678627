<template>
  <div class="mb-20">
    <div class="grid grid-cols-12 gap-y-8 sm:gap-x-8">
      <div class="col-span-12 lg:col-span-4">
        <ImgixImage
          :image="university.thumbnail_image"
          :width="362"
          :height="250"
          class="inline-block"
        />
      </div>
      <div class="col-span-12 lg:col-span-8">
        <h4
          class="text-xl md:text-7 lg:text-2xl text-primary font-suisse-intl mb-12 font-600"
        >
          {{ university.name }}
        </h4>
        <div
          v-if="university.description"
          class="pretty-content text-base md:text-lg font-semi-medium font-suisse-works text-gray-dark leading-relaxed"
          v-html="university.description"
        ></div>
        <div class="mt-4" v-if="university.statement_link">
          <a
            :href="university.statement_link"
            class="flex items-center text-blue-002B48  pl-4"
            style="background-color: #E0E8EE; width: fit-content;"
          >
            <span
              class="font-suisse-intl font-600 text-lg lg:text-xl text-center"
            >
              Read the University's Statement
            </span>
            <svg
              width="57"
              height="57"
              viewBox="0 0 57 57"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.8562 18.8561L18.8562 22.1795L32.0202 22.1913L17.6777 36.5337L20.0347 38.8908L34.3772 24.5483L34.3654 37.7122H37.7124V18.8561H18.8562Z"
                fill="#002B48"
              />
            </svg>
          </a>
        </div>
        <div v-if="university.letter_body" class="mt-6">
          <a
            :href="letterLink"
            class="flex items-center text-white px-4 space-x-2 py-3"
            style="background-color: #002B48; width: fit-content;"
          >
            <span
              class="font-suisse-intl font-600 text-lg lg:text-xl text-center"
            >
              Send the University a Letter
            </span>
            <svg
              width="32"
              height="33"
              viewBox="0 0 32 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M29.3333 8.56852C29.3333 7.10186 28.1333 5.90186 26.6667 5.90186H5.33334C3.86667 5.90186 2.66667 7.10186 2.66667 8.56852V24.5685C2.66667 26.0352 3.86667 27.2352 5.33334 27.2352H26.6667C28.1333 27.2352 29.3333 26.0352 29.3333 24.5685V8.56852ZM26.6667 8.56852L16 15.2219L5.33334 8.56852H26.6667ZM16 17.9019L5.33334 11.2352V24.5685H26.6667V11.2352L16 17.9019Z"
                fill="white"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImgixImage from '../../../admin/asset-manager/components/ImgixImage';

export default {
  components: { ImgixImage },

  props: {
    university: { required: true, type: Object }
  },

  computed: {
    letterLink() {
      if (this.university.letter_subject && this.university.letter_body) {
        return `mailto:${this.university.letter_recipient}?subject=${
          this.university.letter_subject
        }&body=${this.bodyText}`;
      } else if (this.university.letter_body) {
        return `mailto:${this.university.letter_recipient}?body=${
          this.bodyText
        }`;
      } else if (this.university.letter_subject) {
        return `mailto:${this.university.letter_recipient}?subject=${
          this.university.letter_subject
        }`;
      } else {
        return `mailto:${this.university.letter_recipient}`;
      }
    },

    bodyText() {
      return this.university.letter_body.replace(/<br>/gi, '%0D%0A');
    }
  }
};
</script>

<style lang="scss" scoped></style>
