<template>
  <div>
  </div>
</template>
<script>
export default {
    props: {
    video: { reuqired: true, type: String }
  },

  data: function() {
    return {
      yotubeID: this.youtube_parser(this.video)
    };
  },

  methods: {
    youtube_parser(url){
      let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/,
      match = url.match(regExp);

      return ( match&&match[7].length==11 ) ? match[7] : false;
    },

    initIframe(div, id) {
      let iframe = document.createElement('iframe');

      iframe.setAttribute('src', `https://www.youtube.com/embed/${id}?autoplay=0`);
      iframe.setAttribute('frameborder', '0');
      iframe.setAttribute('allowfullscreen', 'allowfullscreen ');
      iframe.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture');
      iframe.classList.add('youtube-iframe')
      
      div.appendChild(iframe);
    }
  },
  mounted() {
    this.initIframe(this.$el, this.yotubeID)
  }
};
</script>