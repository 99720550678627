<template>
  <div class="pt-16 pb-18">
    <div class="container">
      <slot name="cta"></slot>
    </div>

    <div v-if="showStream">
      <slot name="stream" />
    </div>
    <div class="container" v-else>
      <div class="event-countdown">
        <div class="flex flex-col justify-center items-center text-white">
          <div class="flex">
            <div class="stream-countdown-tick">
              {{ days | pad }}
              <div class="stream-countdown-unit">days</div>
            </div>
            <div class="stream-countdown-separator">:</div>
            <div class="stream-countdown-tick">
              {{ hours | pad }}
              <div class="stream-countdown-unit">hours</div>
            </div>
            <div class="stream-countdown-separator">:</div>
            <div class="stream-countdown-tick">
              {{ minutes | pad }}
              <div class="stream-countdown-unit">minutes</div>
            </div>
            <div class="stream-countdown-separator">:</div>
            <div class="stream-countdown-tick">
              {{ seconds | pad }}
              <div class="stream-countdown-unit">seconds</div>
            </div>
          </div>
        </div>
      </div>
      <slot name="text"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    streamTime: { required: true, type: Number },
    forceStream: { required: false, type: Boolean, default: false }
  },

  filters: {
    pad: function(value) {
      return `00${value}`.substr(-2, 2);
    }
  },

  data() {
    return {
      showStream: this.forceStream,
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00'
    };
  },

  mounted() {
    if (!this.showStream) {
      this.tick(); // Do an imeddiate tick
      this.interval = setInterval(() => this.tick(), 500);
    }
  },

  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },

  methods: {
    now() {
      return new Date().getTime();
    },

    areWeThereYet() {
      return this.now() > this.streamTime;
    },

    tick() {
      this.showStream = this.areWeThereYet();
      this.calcTimes();
    },

    calcTimes() {
      const time = this.streamTime - this.now();
      this.days = Math.floor(time / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((time % (1000 * 60)) / 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
.stream-countdown-tick,
.stream-countdown-separator {
  @apply text-white text-center;
  @apply opacity-70;
  font-size: 2.5rem;

  @screen sm {
    font-size: 4.5rem;
  }

  @screen md {
    font-size: 6.5rem;
  }

  @screen lg {
    font-size: 7.5rem;
  }
}
.stream-countdown-unit {
  @apply text-base uppercase;
  @apply opacity-50;
  @apply hidden;

  @screen sm {
    @apply block;
  }

  @screen md {
    @apply text-xl;
  }
}
</style>
