<template>
  <span :data-anchor="name"></span>
</template>

<script>
export default {
  props: {
    name: { required: true, type: String }
  },

  mounted() {
    if (this.shouldHandle()) {
      setTimeout(() => this.scroll(), 500);
    }

    window.addEventListener('hashchange', this.handleHashChange);
    BUS.$on(`anchor.${this.name}`, this.scroll);
  },

  beforeDestroy() {
    window.removeEventListener('hashchange', this.handleHashChange);
    BUS.$off(`anchor.${this.name}`, this.scroll);
  },

  methods: {
    shouldHandle() {
      return window.location.hash === `#${this.name}`;
    },

    scroll() {
      const anchorTop = this.$el.getBoundingClientRect().top;

      window.scrollTo({
        top: window.scrollY + anchorTop + this.offset(),
        behavior: 'smooth'
      });
    },

    offset() {
      return -(
        document.querySelector('#header').getBoundingClientRect().height + 20
      );
    },

    handleHashChange() {
      if (!this.shouldHandle()) {
        return;
      }

      this.scroll();
    }
  }
};
</script>
