<template>
  <div class="container pb-10">
    <div class="flex justify-between rounded-sm">
      <div class="flex w-full bg-white">
        <Multiselect
          v-model="selectedOption"
          :options="options"
          :allowEmpty="false"
          placeholder="Choose university"
          class="w-full outline-none text-blue-002B48 font-suisse-intl text-base"
        >
        </Multiselect>
      </div>
    </div>

    <div class="pt-12">
      <div v-if="visibleUniversities.length">
        <University
          v-for="university in visibleUniversities"
          :key="university._id"
          :university="university"
        >
        </University>
      </div>
      <p v-else class="mt-8 text-primary text-2xl font-medium">
        No universities found!
      </p>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import University from './University.vue';

export default {
  components: { University, Multiselect },

  props: {
    universities: { required: true, type: Array }
  },

  data() {
    return {
      loading: false,
      selectedOption: null
    };
  },

  computed: {
    options() {
      let options = this.universities.map((x) => x.name);

      return ['All Universities', ...options];
    },
    visibleUniversities() {
      // Sort universities alphabetically by name
      let copyUniversities = JSON.stringify(this.universities);
      let sortedUniversities = JSON.parse(copyUniversities).sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      if (
        this.selectedOption == 'All Universities' ||
        this.selectedOption == null
      ) {
        return sortedUniversities;
      } else {
        return sortedUniversities.filter(
          (university) => university.name == this.selectedOption
        );
      }
    }
  }
};
</script>

<style lang="sass">
@import 'vue-multiselect/dist/vue-multiselect.min.css';
</style>

<style scoped lang="scss">
::v-deep {
  .multiselect__tags {
    border: 2px solid #e0e8ee;
    height: 56px;
    border-radius: 0 !important;
    padding: 15px 40px 0 32px;
    @apply font-suisse-intl text-base text-blue-002B48 text-base text-opacity-100;
  }

  .multiselect__select {
    height: 54px;
  }

  .multiselect__option--highlight,
  .multiselect__option--highlight:after {
    background: #002b48;
    content: '';
  }

  .multiselect__option--highlight .option__text {
    color: #fff !important;
  }

  .multiselect__content {
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
  }

  .multiselect__element::marker {
    display: none;
  }

  .multiselect__element {
    @apply font-suisse-intl text-base text-blue-002B48 text-base;
  }

  .multiselect::before {
    content: url('../../../images/icons/search-before.svg');
    position: absolute;
    top: 17px;
    left: 7px;
  }
}
</style>
