<template>
  <img :src="src" :height="safeHeight" :width="safeWidth" :style="styles" :alt="safeAlt" />
</template>

<script>
import imgixURL from '../helpers/imgix-url';

const BLANK_IMAGE =
  'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

export default {
  name: 'ImgixImage',

  props: {
    alt: String,
    crop: String,
    crossorigin: { type: String, default: 'anonymous' },
    fit: { type: String, default: 'crop' },
    height: Number,
    width: Number,
    image: Object,
    options: Object
  },

  data() {
    return { src: BLANK_IMAGE };
  },

  computed: {
    safeAlt() {
      return this.alt || this.image.path.split('/').pop();
    },

    safeHeight() {
      if (this.height) return this.height;

      if (this.aspectRatio) return Math.round(this.width / this.aspectRatio);

      return null;
    },

    safeWidth() {
      if (this.width) return this.width;

      if (this.aspectRatio) return Math.round(this.height * this.aspectRatio);

      return null;
    },

    styles() {
      return [
        this.safeWidth ? `width: ${this.safeWidth}px` : null,
        this.safeHeight ? `height: ${this.safeHeight}px` : null
      ]
        .filter((value) => !!value)
        .join(';');
    },

    aspectRatio() {
      if (!this.image.meta.width || !this.image.meta.height) {
        return null;
      }
      return this.image.meta.width / this.image.meta.height;
    }
  },

  mounted() {
    let src = this.getImgixSrc();
    this.loadImage(src).then((src) => (this.src = src));
  },

  methods: {
    getDPR() {
      return (window.devicePixelRatio || 1).toFixed(2);
    },

    getImgixSrc(overwrites = {}) {
      return imgixURL(this.image.path, {
        ...this.options,
        crop: this.crop,
        fit: this.fit,
        h: this.height,
        w: this.width,
        dpr: this.getDPR(),
        ...overwrites
      });
    },

    loadImage(src) {
      return new Promise((resolve) => {
        this.loadingImage = new Image();
        this.loadImageCallback = () => resolve(src);

        this.loadingImage.addEventListener('load', this.loadImageCallback, true);
        this.loadingImage.addEventListener('error', this.loadImageCallback, true);
        this.loadingImage.crossOrigin = 'Anonymous';
        this.loadingImage.src = src;

        // Resolve with the cached image if present
        if (this.loadingImage.complete || this.loadingImage.readyState === 4) {
          resolve(src);
        }
      });
    }
  }
};
</script>
