<template>
  <div class="my-8">
    <h4 class="mb-4 capitalize text-primary text-base font-bold">
      Filter by date
    </h4>
    <div
      class="bg-light flex flex-col sm:flex-row lg:flex-col px-2 py-3 rounded-sm"
    >
      <input
        class="date-range-selector-input"
        :class="{ 'opacity-40': !state.from && !focused.from }"
        @focus="focused.from = true"
        @blur="focused.from = false"
        type="date"
        name="from"
        placeholder="dd-mm-yyyy"
        v-model="state.from"
        :max="state.to"
      />
      <span class="flex-auto text-center text-primary text-sm text-opacity-60">
        - to -
      </span>
      <input
        class="date-range-selector-input"
        :class="{ 'opacity-40': !state.to && !focused.to }"
        @focus="focused.to = true"
        @blur="focused.to = false"
        type="date"
        name="to"
        placeholder="dd-mm-yyyy"
        v-model="state.to"
        :min="state.from"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DateRangeSelector',

  props: {
    from: String,
    to: String
  },

  data() {
    return {
      focused: { from: false, to: false },
      state: { from: this.from, to: this.to }
    };
  }
};
</script>

<style>
.date-range-selector-input {
  background-color: transparent;
  font-size: 1rem;
}
</style>
