<template>
  <div>
    <slot />
  </div>
</template>

<script>
import footnotesScroller from '../footnotes-scroller';
export default {
  mounted() {
    footnotesScroller(this.$el);
  }
};
</script>
