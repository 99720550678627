var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "button",
        {
          staticClass: "focus:outline-none pb-4",
          attrs: { "aria-expanded": _vm.isOpen, "aria-controls": _vm.id },
          on: {
            click: function ($event) {
              return _vm.toggleAccordion()
            },
          },
        },
        [
          _c("span", { staticClass: "text-base text-blue hover:underline" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass: "w-3 transition-all duration-200 transform",
              class: {
                "rotate-180": _vm.isOpen,
                "rotate-0": !_vm.isOpen,
              },
              attrs: {
                fill: "none",
                stroke: "currentColor",
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 16 10",
                "aria-hidden": "true",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M15 1.2l-7 7-7-7",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: { name: "contents" },
          on: {
            enter: _vm.enter,
            "after-enter": _vm.afterEnter,
            leave: _vm.leave,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isOpen,
                  expression: "isOpen",
                },
              ],
              ref: "content",
              attrs: { id: _vm.id },
            },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }