var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.youtubeItems, function (youtubeItem) {
        return _c(
          "div",
          {
            key: youtubeItem.id,
            staticClass: "selected-video ec-zagreb-video mb-8",
          },
          [
            _c("youtube-video", {
              class: { hidden: _vm.selectedVideoId != youtubeItem.youtubeId },
              attrs: { video: youtubeItem.youtubeId },
            }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("div", { staticClass: "custom-swiper-container mt-8" }, [
        _c(
          "div",
          {
            ref: "rootEl",
            staticClass: "ec-zagreb-swiper swiper-container",
            attrs: { "manual-init": "" },
          },
          [
            _c(
              "div",
              { staticClass: "swiper-wrapper ec-zagreb-slide mr-8" },
              _vm._l(_vm.youtubeItems, function (youtubeItem) {
                return _c(
                  "div",
                  { key: youtubeItem.id, staticClass: "swiper-slide mr-8" },
                  [
                    _c(
                      "button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.selectVideo(youtubeItem.youtubeId)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "h-full",
                          attrs: { src: youtubeItem.thumbnail },
                        }),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }