<template>
  <component :is="tag">
    <slot v-if="state === 'loading'" name="loading" />
    <slot v-if="state === 'loaded'" name="loaded" />
    <slot v-if="state === 'error'" name="error" />
  </component>
</template>

<script>
export default {
  props: {
    tag: { type: String, required: false, default: 'div' },
    src: { type: String, required: true },
    onLoad: { type: Function, required: false, default: () => {} },
    onError: { type: Function, required: false, default: () => {} }
  },

  data() {
    return {
      state: 'uninitialized'
    };
  },

  mounted() {
    this.state = 'loading';

    this.$loadScript(this.src)
      .then(() => {
        this.state = 'loaded';
        this.onLoad();
      })
      .catch(() => {
        this.state = 'error';
        this.onError();
      });
  }
};
</script>
