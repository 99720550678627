var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "podcast-component flex flex-col md:flex-row" },
    [
      _c(
        "audio",
        {
          ref: "player",
          staticClass: "hidden",
          attrs: { id: "audio-player", preload: "" },
        },
        [
          _c("source", {
            attrs: { src: _vm.podcastData.podcast_link, type: "audio/mpeg" },
          }),
          _vm._v("\n    Your browser does not support the audio tag.\n  "),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "player-row w-full" }, [
        _c(
          "div",
          {
            staticClass: "flex flex-col md:flex-row w-full max-w-5xl md:pr-10",
          },
          [
            _c("ImgixImage", {
              staticClass: "rounded-sm self-center",
              attrs: { image: _vm.podcastData.image, width: 180, height: 180 },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex flex-col w-full md:ml-6 pt-6 pr-10 pb-6" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-col-reverse md:flex-row relative ml-5",
                  },
                  [
                    _c(
                      "svg",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isPlaying,
                            expression: "!isPlaying",
                          },
                        ],
                        attrs: {
                          width: "54",
                          height: "54",
                          viewBox: "0 0 54 54",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toggleAudio()
                          },
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d: "M0.332031 27C0.332031 12.28 12.2787 0.333374 26.9987 0.333374C41.7187 0.333374 53.6654 12.28 53.6654 27C53.6654 41.72 41.7187 53.6667 26.9987 53.6667C12.2787 53.6667 0.332031 41.72 0.332031 27ZM37.6654 27L21.6654 15V39L37.6654 27Z",
                            fill: "#0F5DA9",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "svg",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isPlaying,
                            expression: "isPlaying",
                          },
                        ],
                        attrs: {
                          width: "54",
                          height: "54",
                          viewBox: "0 0 54 54",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toggleAudio()
                          },
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d: "M26.9987 0.333374C12.2787 0.333374 0.332031 12.28 0.332031 27C0.332031 41.72 12.2787 53.6667 26.9987 53.6667C41.7187 53.6667 53.6654 41.72 53.6654 27C53.6654 12.28 41.7187 0.333374 26.9987 0.333374ZM24.332 37.6667H18.9987V16.3334H24.332V37.6667ZM29.6654 37.6667H34.9987V16.3334H29.6654V37.6667Z",
                            fill: "#0F5DA9",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "mb-5 md:ml-5 md:mb-0" }, [
                      _vm.podcastData.show == undefined
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "text-gray-dark text-sm font-bold font-primary text-opacity-80",
                            },
                            [
                              _vm._v(
                                "\n              Jewish World Podcast\n            "
                              ),
                            ]
                          )
                        : _c(
                            "span",
                            {
                              staticClass:
                                "text-gray-dark text-sm font-bold font-primary text-opacity-80",
                            },
                            [
                              _vm._v(
                                " \n              " +
                                  _vm._s(_vm.podcastData.show.title) +
                                  "\n            "
                              ),
                            ]
                          ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.audioLoaded,
                              expression: "audioLoaded",
                            },
                          ],
                          staticClass: "max-w-md pointer-events-none flex",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "mt-2 text-black font-medium text-lg",
                            },
                            [
                              _vm._v(
                                " \n                " +
                                  _vm._s(_vm.podcastData.title) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "podcast-time absolute right-0 bottom-0" },
                      [
                        _c("span", {
                          staticClass:
                            "text-base text-dark-gray text-opacity-80 font-bold",
                          domProps: { innerHTML: _vm._s(_vm.elapsedTime()) },
                        }),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "mt-5 ml-5 md:ml-0" }, [
                  _c(
                    "div",
                    { staticClass: "overlay-container relative w-full h-full" },
                    [
                      _c("div", { staticClass: "slider-container" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.playbackTime,
                              expression: "playbackTime",
                            },
                          ],
                          staticClass: "slider w-full",
                          attrs: {
                            type: "range",
                            min: "0",
                            max: _vm.audioDuration,
                            id: "position",
                            name: "position",
                          },
                          domProps: { value: _vm.playbackTime },
                          on: {
                            __r: function ($event) {
                              _vm.playbackTime = $event.target.value
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.audioLoaded,
                              expression: "!audioLoaded",
                            },
                          ],
                          staticClass:
                            "w-full absolute top-0 bottom-0 right-0 left-0 px-2 pointer-events-none",
                          staticStyle: { color: "#94bcec" },
                        },
                        [
                          _vm._v(
                            "\n              Loading please wait...\n            "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }