<template>
  <div class="highholidays">
    <slot :activeRabbi="activeRabbi" :toggleActiveRabbi="toggleActiveRabbi" />
  </div>
</template>

<script>
export default {
  props: {
    initialRabbi: { required: false, type: Object, default: null },
    anchorName: { required: true, type: String }
  },

  data() {
    return {
      activeRabbi: this.initialRabbi
    };
  },

  mounted() {
    this.scrollToAnchor();
  },

  methods: {
    toggleActiveRabbi(rabbi) {
      this.activeRabbi = rabbi;
    },

    scrollToAnchor() {
      if (window.location.hash !== `#${this.anchorName}`) {
        return;
      }

      // First scroll to top
      window.scrollTo({ top: 0, left: 0 });
      setTimeout(() => {
        const header = document.querySelector('#header');
        const anchor = document.querySelector(`#${this.anchorName}-anchor`);
        const offset = header.getBoundingClientRect().height;
        const top = anchor.getBoundingClientRect().y - offset;

        window.scrollTo({
          top,
          left: 0,
          behavior: 'smooth'
        });
      }, 1200);
    }
  }
};
</script>

<style lang="scss">
.highholidays {
  .inner-hero-img {
    background-size: auto 100vh;
  }
}

.rabbi-flag {
  @apply absolute bottom-0 left-0 right-0;
  @apply flex items-center;
  @apply p-3;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );

  img {
    @apply h-5 inline-block;
    @apply mr-2;
    @apply rounded;
  }
}

.high-holiday-intro {
  p + p {
    @apply mt-6;
  }
}

.high-holiday-rabbis {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 10rem;
  grid-template-rows: 17rem;
  gap: 0;
  @apply overflow-x-auto overflow-y-hidden;
  @apply -mx-2 px-2;
  @apply relative;
  top: 0.25px;

  &::-webkit-scrollbar-track {
    background-color: #274466 !important;
  }

  &::-webkit-scrollbar {
    width: 12px !important;
    background-color: #f5f5f5 !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    background-color: #fff !important;
  }
}

.high-holiday-rabbi-outter {
  @apply relative h-full;

  .left-sub,
  .right-sub {
    @apply hidden;
    @apply absolute bottom-0;
    @apply w-2 h-2;
  }

  .left-sub {
    left: -0.5rem;
  }
  .right-sub {
    right: -0.5rem;
  }

  &.active {
    .left-sub,
    .right-sub {
      @apply block;
    }

    .high-holiday-rabbi {
      @apply bg-white;

      h2 {
        @apply text-primary;
      }
    }
  }
}

.high-holiday-rabbi {
  @apply pb-6;
  @apply px-4 pt-4;
  @apply rounded-t;
  @apply h-full;
  cursor: pointer;

  h2 {
    @apply text-white-a8;
  }
}

.high-holiday-rabbis2 {
  @apply bg-light rounded;
}

.high-holiday-rabbi2-outter {
  &.active {
    .high-holiday-rabbi2 {
      background: #0f3f7a;

      .rabbi2-info {
        @apply text-white;
      }
    }
  }
}

.high-holiday-rabbi2 {
  @apply flex items-center;
  @apply px-6 py-3;
  @apply rounded;
  cursor: pointer;
  width: 22.625rem;
  height: 7.75rem;

  .rabbi2-photo {
    width: 100px;
    height: 100px;
    @apply mr-8;
  }

  .rabbi2-info {
    @apply text-gray-dark;
  }
}
</style>
