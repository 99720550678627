var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.form.isSubmitted
        ? _vm._t("form", null, { form: _vm.form, submit: _vm.submit })
        : _vm._t("submitted"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }