import Vue from 'vue';

export default function() {
  Vue.component('offices', {
    data: function() {
      return {
        offices: [
          {
            key: 'new-york',
            city: 'New York',
            tel: '+1 212 755 57 70',
            email: 'new.york@wjc.org',
            top: '50%',
            left: '27%'
          },
          {
            key: 'israel',
            city: 'Israel',
            tel: '+972 2 633 30 00',
            email: 'jerusalem@wjc.org',
            top: '55.5%',
            left: '56.5%'
          },
          {
            key: 'brussels',
            city: 'Brussels',
            tel: '+32 552 09 00',
            email: 'brussels@wjc.org',
            top: '45%',
            left: '48%'
          },
          {
            key: 'washington',
            city: 'Washington D.C.',
            tel: '+1 202 898 25 00',
            email: 'washington.dc@wjc.org',
            top: '53%',
            left: '25%'
          },
          {
            key: 'buenos-aires',
            city: 'Buenos Aires',
            tel: '+54 11 49 62 50 28',
            email: 'buenos.aires@wjc.org',
            top: '89%',
            left: '28%'
          },
          {
            key: 'geneva',
            city: 'Geneva',
            tel: '+41 22 734 13 25',
            email: 'geneva@wjc.org',
            top: '47.6%',
            left: '48.8%'
          },
          {
            key: 'zagreb',
            city: 'Zagreb',
            tel: '+385 1 775 70 00',
            email: 'zagreb@wjc.org',
            top: '48.5%',
            left: '51%'
          },
          {
            key: 'vilnius',
            city: 'Vilnius',
            tel: '+370 5 208 03 06',
            email: 'vilnius@wjc.org',
            top: '42%',
            left: '53.5%'
          },
          {
            key: 'chisinau',
            city: 'Chișinău',
            // tel: '',
            email: 'chisinau@wjc.org',
            top: '47%',
            left: '54%'
          },
          {
            key: 'tbilisi',
            city: 'Tbilisi',
            // tel: '',
            email: 'tbilisi@wjc.org',
            top: '50%',
            left: '59%'
          },
          {
            key: 'stockholm',
            city: 'Stockholm',
            tel: '+46 707 308 851',
            email: 'stockholm@wjc.org',
            top: '38%',
            left: '51.5%'
          },
          {
            key: 'vienna',
            city: 'Vienna',
            tel: '+43 1 928 010 200',
            email: 'vienna@wjc.org',
            top: '46.7%',
            left: '50.8%'
          },
          {
            key: 'berlin',
            city: 'Berlin',
            tel: '+49 30 16634480',
            email: 'berlin@wjc.org',
            top: '43.7%',
            left: '49.8%'
          },
          {
            key: 'rome',
            city: 'Rome',
            tel: '+39 6 45 25 70 65',
            email: 'rome@wjc.org',
            top: '50.5%',
            left: '50.5%'
          }
        ],
        current: false
      };
    },

    mounted() {
      this.current = this.offices[0];
    }
  });
}
