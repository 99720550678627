var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-span-12 lg:col-span-4" }, [
    _vm.representative.thumbnail_image && _vm.representative.link
      ? _c(
          "a",
          {
            staticClass: "media-element relative",
            attrs: { href: _vm.representative.link, target: "_blank" },
          },
          [
            _c("ImgixImage", {
              staticClass: "inline-block representative-img",
              attrs: {
                image: _vm.representative.thumbnail_image,
                width: 362,
                height: 239,
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "hover-element" }, [
              _c(
                "svg",
                {
                  staticClass: "yellow-arrow",
                  attrs: {
                    width: "64",
                    height: "64",
                    viewBox: "0 0 64 64",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: { d: "M64 0V64L0 0H64Z", fill: "#F5DE4C" },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M38.881 13.3137L38.881 15.3078L46.7794 15.3148L38.1739 23.9203L39.5881 25.3345L48.1936 16.729L48.1865 24.6274H50.1947V13.3137H38.881Z",
                      fill: "#1C2B59",
                    },
                  }),
                ]
              ),
            ]),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "youtube-video-width" },
          [
            _c("youtube-video", {
              staticClass: "h-full",
              attrs: {
                video: _vm.representative.video_link,
                width: 362,
                height: 239,
              },
            }),
          ],
          1
        ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "mt-4 mb-2 font-400 font-suisse-intl text-lg flex items-center",
      },
      [
        _vm._v("\n    " + _vm._s(_vm.representative.member) + "\n    "),
        _c("img", {
          staticClass: "h-4 rounded m-2",
          attrs: { src: _vm.flag, alt: _vm.representative.country.names[0] },
        }),
      ]
    ),
    _vm._v(" "),
    _c(
      "h4",
      { staticClass: "text-lg text-primary font-suisse-intl font-600" },
      [_vm._v("\n    " + _vm._s(_vm.representative.title) + "\n  ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }