<template>
  <div>
    <slot
      :days="days"
      :hours="hours"
      :minutes="minutes"
      :seconds="seconds"
    ></slot>
  </div>
</template>

<script>
import padStart from 'lodash/padStart';

export default {
  props: {
    date: Number
  },

  data() {
    return {
      interval: 0,
      days: 0,
      minutes: 0,
      hours: 0,
      seconds: 0
    };
  },

  mounted() {
    // Update the count down every 1 second
    this.timerCount();
    this.interval = setInterval(() => this.timerCount(), 1000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    timerCount() {
      let now = new Date().getTime();
      let timeLeft = this.date - now;

      if (timeLeft > 0) {
        this.calcTime(timeLeft);
      } else {
        clearInterval(this.interval);
      }
    },

    calcTime(time) {
      this.days = this.round(time / (1000 * 60 * 60 * 24));
      this.hours = this.round((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = this.round((time % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = this.round((time % (1000 * 60)) / 1000);
    },

    round(number) {
      return padStart(Math.floor(number), 2, '0');
    }
  }
};
</script>
