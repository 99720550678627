var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    { staticClass: "btn btn-white uppercase mt-14 mx-4 text-sm sm:text-lg" },
    [
      _vm._v("\n  Add to your calendar\n  "),
      _c("div", { staticClass: "atc-dropdown" }, [
        _c(
          "a",
          {
            attrs: {
              href: _vm.links.ics,
              target: "_blank",
              rel: "nofollow noreferrer",
            },
          },
          [_vm._v("Apple")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: _vm.links.google,
              target: "_blank",
              rel: "nofollow noreferrer",
            },
          },
          [_vm._v("\n      Google\n    ")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: _vm.links.office365,
              target: "_blank",
              rel: "nofollow noreferrer",
            },
          },
          [_vm._v("\n      Office 365\n    ")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: _vm.links.outlook,
              target: "_blank",
              rel: "nofollow noreferrer",
            },
          },
          [_vm._v("\n      Outlook\n    ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }