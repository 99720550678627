var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "modal-mask" }, [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c(
          "div",
          { staticClass: "modal-container" },
          [
            _c("button", {
              staticClass: "modal-close-button font-sans font-bold",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            }),
            _vm._v(" "),
            _vm._t("default"),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }