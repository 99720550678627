<template>
  <div>
    <div ref="modal" v-if="showImage" @click="closeImg()" class=" bg-black bg-opacity-60 fixed inset-0 z-50">
      <div class="container flex flex-col lg:flex-row justify-center  w-11/12 m-auto pt-32">
        <div class="modal-image-mini-swiper" id="image-target" v-html="imgContent.outerHTML"></div>
      </div>
    </div>

    <div class="custom-swiper-container mini-swiper-img">
      <div ref="rootEl" class="ec-zagreb-swiper swiper-container" manual-init>
        <div class="swiper-wrapper mr-8">
          <div v-for="item in items" :key="item.id" class="swiper-slide mini-swiper-img ">
            <img @click="handleImgClick($event)" :srcset="item" class="mini-swiper-img "/>
          </div>
        </div>
      </div>

      <div>
        <div class="swiper-button-prev swiper-nav-btn mt-0 js-swiper-prev w-12 h-12 fix-left" style="background: rgba(8, 40, 79, 0.24); top: 44%">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M8 0L9.41 1.41L3.83 7H16V9H3.83L9.41 14.59L8 16L0 8L8 0Z" fill="white"/>
          </svg>
        </div>
        <div class="swiper-button-next swiper-nav-btn mt-0 js-swiper-next h-12 w-12 fix-right" style="background: rgba(8, 40, 79, 0.24); top: 44%">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z" fill="white"/>
          </svg>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
import Swiper from 'swiper';

const DEFAULT_CONFIG = {
  watchOverflow: true,
  autoplay: true,
  simulateTouch: true,
  slidesPerView: 'auto',
  spaceBetween: 32,
  slidesPerView: 1
};

export default {
  props: {
    items: { required: true}
  },

  data() {
    return {
      swiper: null,
      showImage: false,
      imgContent: null
    };
  },

  mounted() {
    this.swiper = new Swiper(this.$refs.rootEl, {
      ...DEFAULT_CONFIG,
      navigation: {
        nextEl: this.$el.querySelector('.js-swiper-next'),
        prevEl: this.$el.querySelector('.js-swiper-prev')
      },
      breakpoints: this.breakpoints,
      ...this.config
    });
  },

  methods: {
    closeImg() {
      this.showImage = false
    },
    handleImgClick(event) {
      this.showImage = !this.showImage;
      this.imgContent = event.target;
    }
  }
};
</script>
<style>
.fix-left {
  background: rgba(8, 40, 79, 0.5)!important;
  top: 78%!important;
  left: 60%;
}

.fix-right {
  background: rgba(8, 40, 79, 0.5)!important;
  top: 78%!important;
  left: 85%!important;
}

</style>