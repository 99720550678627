<template>
  <div>
    <button
      @click="toggleAccordion()"
      class="focus:outline-none pb-4"
      :aria-expanded="isOpen"
      :aria-controls="id"
    >
      <span class="text-base text-blue hover:underline">{{ title }}</span>
      <svg
        class="w-3 transition-all duration-200 transform"
        :class="{
          'rotate-180': isOpen,
          'rotate-0': !isOpen
        }"
        fill="none"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 10"
        aria-hidden="true"
      >
        <path
          d="M15 1.2l-7 7-7-7"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>

    <transition
      name="contents"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
    >
      <div v-show="isOpen" :id="id" ref="content">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    readMoreLabel: { required: false, type: String, default: 'Read More' },
    readLessLabel: { required: false, type: String, default: 'Read Less' }
  },

  data() {
    return {
      isOpen: false
    };
  },

  computed: {
    title() {
      return this.isOpen ? this.readLessLabel : this.readMoreLabel;
    },
    id() {
      return this._uid;
    }
  },

  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },

    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = 'absolute';
      element.style.visibility = 'hidden';
      element.style.height = 'auto';

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = 'auto';
    },
    leave(element) {
      const height = getComputedStyle(element).height;

      element.style.height = height;

      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    }
  }
};
</script>

<style scoped>
.contents-enter-active,
.contents-leave-active {
  transition: height 0.7s ease;
  overflow: hidden;
}

.contents-enter,
.contents-leave-to {
  height: 0;
}
</style>
