<template>
  <div v-if="showStream">
    <slot name="stream" />
  </div>
  <div v-else class="halle-timer">
    <div class="flex flex-col justify-center items-center text-white">
      <div class="uppercase text-xl sm:text-2xl opacity-40">
        stream starting
      </div>
      <div class="flex">
        <div class="halle-timer-tick">
          {{ days | pad }}
          <div class="halle-timer-unit">days</div>
        </div>
        <div class="halle-timer-separator">:</div>
        <div class="halle-timer-tick">
          {{ hours | pad }}
          <div class="halle-timer-unit">hours</div>
        </div>
        <div class="halle-timer-separator">:</div>
        <div class="halle-timer-tick">
          {{ minutes | pad }}
          <div class="halle-timer-unit">minutes</div>
        </div>
        <div class="halle-timer-separator">:</div>
        <div class="halle-timer-tick">
          {{ seconds | pad }}
          <div class="halle-timer-unit">seconds</div>
        </div>
      </div>

      <add-to-calendar :time="streamTime" />
    </div>
  </div>
</template>

<script>
import AddToCalendar from './add-to-calendar';

const streamTime = 1601395200 * 1000;
const now = () => new Date().getTime();
const areWeThereYet = () => now() > streamTime;

export default {
  components: {
    'add-to-calendar': AddToCalendar
  },

  props: {
    forceStream: { required: false, type: Boolean, default: false }
  },

  filters: {
    pad: function(value) {
      return `00${value}`.substr(-2, 2);
    }
  },

  data() {
    return {
      showStream: this.forceStream,
      streamTime,
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00'
    };
  },

  mounted() {
    if (!this.showStream) {
      this.tick(); // Do an imeddiate tick
      this.interval = setInterval(() => this.tick(), 500);
    }
  },

  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },

  methods: {
    tick() {
      this.showStream = areWeThereYet();
      this.calcTimes();
    },

    calcTimes() {
      const time = streamTime - now();
      this.days = Math.floor(time / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((time % (1000 * 60)) / 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
.halle-timer {
  background: #071e3b;
  @apply py-8;

  @screen md {
    @apply py-0;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    width: 100%;

    & > div {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
}

.halle-timer-tick,
.halle-timer-separator {
  @apply text-white text-center;
  @apply opacity-70;
  font-size: 2.5rem;

  @screen sm {
    font-size: 4.5rem;
  }

  @screen md {
    font-size: 6.5rem;
  }

  @screen lg {
    font-size: 7.5rem;
  }
}
.halle-timer-unit {
  @apply text-base uppercase;
  @apply opacity-50;
  @apply hidden;

  @screen sm {
    @apply block;
  }

  @screen md {
    @apply text-xl;
  }
}
</style>
