
<template>
  <div class="podcast-component flex flex-col md:flex-row">
    <audio id="audio-player"   
      class="hidden"
      ref="player"
      preload
      >
      <source :src="podcastData.podcast_link" type="audio/mpeg">
      Your browser does not support the audio tag.
    </audio>
     
    <div class="player-row w-full">
      <div class="flex flex-col md:flex-row w-full max-w-5xl md:pr-10">
        <ImgixImage :image="podcastData.image" :width="180" :height="180" class="rounded-sm self-center" />

        <div class="flex flex-col w-full md:ml-6 pt-6 pr-10 pb-6">
          <div class="flex flex-col-reverse md:flex-row relative ml-5">
            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg"
              @click="toggleAudio()"
              v-show="!isPlaying">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.332031 27C0.332031 12.28 12.2787 0.333374 26.9987 0.333374C41.7187 0.333374 53.6654 12.28 53.6654 27C53.6654 41.72 41.7187 53.6667 26.9987 53.6667C12.2787 53.6667 0.332031 41.72 0.332031 27ZM37.6654 27L21.6654 15V39L37.6654 27Z" fill="#0F5DA9"/>
            </svg>
            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg"
              @click="toggleAudio()"
              v-show="isPlaying">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M26.9987 0.333374C12.2787 0.333374 0.332031 12.28 0.332031 27C0.332031 41.72 12.2787 53.6667 26.9987 53.6667C41.7187 53.6667 53.6654 41.72 53.6654 27C53.6654 12.28 41.7187 0.333374 26.9987 0.333374ZM24.332 37.6667H18.9987V16.3334H24.332V37.6667ZM29.6654 37.6667H34.9987V16.3334H29.6654V37.6667Z" fill="#0F5DA9"/>
            </svg>

            <div class="mb-5 md:ml-5 md:mb-0" >
              <span class="text-gray-dark text-sm font-bold font-primary text-opacity-80" v-if="podcastData.show == undefined">
                Jewish World Podcast
              </span>
              <span class="text-gray-dark text-sm font-bold font-primary text-opacity-80" v-else > 
                {{ podcastData.show.title }}
              </span>
              <div v-show="audioLoaded" class="max-w-md pointer-events-none flex">
                <div class="mt-2 text-black font-medium text-lg"> 
                  {{  podcastData.title }}
                </div>
              </div>
            </div>
            <div class="podcast-time absolute right-0 bottom-0">
              <span class="text-base text-dark-gray text-opacity-80 font-bold" v-html="elapsedTime()"> </span>
            </div>
          </div>

          <div class="mt-5 ml-5 md:ml-0">
            <div class="overlay-container relative w-full h-full">
              <div class="slider-container">
                <input
                    v-model="playbackTime"
                    type="range"
                    min="0"
                    :max="audioDuration"
                    class="slider w-full"
                    id="position"
                    name="position"
                />
              </div>
              <div v-show="!audioLoaded"
                class="w-full absolute top-0 bottom-0 right-0 left-0 px-2 pointer-events-none"
                style="color: #94bcec">
                Loading please wait...
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImgixImage from '../../../admin/asset-manager/components/ImgixImage';

export default {
  components: { ImgixImage },
  
  props: {
    podcastData: { required: true,  type: Object }
  },

  data() {
    return {
      playbackTime: 0,
      audioDuration: 100,
      audioLoaded: false,
      isPlaying: false
    };
  },

  methods: {

    initSlider() {
      let audio = this.$refs.player;

      if (audio) {
          this.audioDuration = Math.round(audio.duration);
      }
    },

    convertTime(seconds){
      const format = val => `0${Math.floor(val)}`.slice(-2);
      let hours = seconds / 3600,
          minutes = (seconds % 3600) / 60;
      return [minutes, seconds % 60].map(format).join(":");
    },

    totalTime() {
      let audio = this.$refs.player;

      if (audio) {
        let seconds = audio.duration;
        return this.convertTime(seconds);
      } 
      else {
          return '00:00';
      }
    },

    elapsedTime() {
      let audio = this.$refs.player;

      if (audio) {
        let seconds = audio.currentTime;
        return this.convertTime(seconds);
      } 
      else {
        return '00:00';
      }
    },

    playbackListener(e) {
      let audio = this.$refs.player;

      this.playbackTime = audio.currentTime;
      
      audio.addEventListener("ended", this.endListener);
      audio.addEventListener("pause", this.pauseListener);
    },

    pauseListener() {
      this.isPlaying = false;
      this.listenerActive = false;
      this.cleanupListeners();
    },

    endListener() {
      this.isPlaying = false;
      this.listenerActive = false;
      this.cleanupListeners();
    },

    cleanupListeners() {
      let audio = this.$refs.player;
      audio.removeEventListener("timeupdate", this.playbackListener);
      audio.removeEventListener("ended", this.endListener);
      audio.removeEventListener("pause", this.pauseListener);
    },

    toggleAudio() {
      let audio = this.$refs.player;

      if (audio.paused) {
        audio.play();
        this.isPlaying = true;
      } 
      else {
        audio.pause();
        this.isPlaying = false;
      }
    },
  },

  mounted: function() {
    this.$nextTick(function() {
      let audio=this.$refs.player;

      audio.addEventListener("loadedmetadata", function(event) { this.initSlider() }.bind(this) );
      audio.addEventListener("canplay", function(event) { this.audioLoaded = true }.bind(this) );

      this.$watch("isPlaying", function() {
        if(this.isPlaying) {
          let audio = this.$refs.player;
          this.initSlider();
          if(!this.listenerActive) {
            this.listenerActive = true;
            audio.addEventListener("timeupdate", this.playbackListener);
          }
        }
      });

      this.$watch("playbackTime",function() {
        let audio = this.$refs.player,
          diff=Math.abs(this.playbackTime - this.$refs.player.currentTime);
      
        if(diff>0.01) {
          this.$refs.player.currentTime = this.playbackTime;
        }
      });
    });
  }
};
</script>

<style>
  .play-button{
      height: 45px
  }

  input[type="range"] {
      margin: auto;
      -webkit-appearance: none;
      position: relative;
      overflow: hidden;
      width: 100%;
      cursor: pointer;
      outline: none;
      border-radius: 16px;

  }

  ::-webkit-slider-runnable-track {
      background: rgba(6, 85, 164, 0.1);
  }

  ::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 0;
      height: 16px;
      background: #fff;
      box-shadow: -100vw 0 0 100vw #0F5DA9; 
      border: none; 
  }

  ::-moz-range-track {
      height: 16px;
      background: #ddd;
      border-radius: 16px;
  }
  ::-moz-range-thumb {
      background: #fff;
      height: 16px;
      width: 0; 
      border: none;
      border-radius: 0 !important;
      box-shadow: -100vw 0 0 100vw transparent; 
      box-sizing: border-box;
      border-radius: 16px;

  }

  ::-ms-fill-lower {
      background: #0F5DA9;
      border-radius: 16px;
  }

  ::-ms-thumb {
      background: #fff;
      border: 2px solid #999;
      height: 16px;
      width: 20px;
      box-sizing: border-box;
      border-radius: 16px;
  }

  ::-ms-track {
      background: #ddd;
      color: transparent;
      height: 10px;
      border: none;
  }
</style>